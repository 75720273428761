import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { currencies } from 'utils/enums/marketplace';
import { getCurrentMarketplace, getMarketplaceBaseUrl, notify } from 'utils/marketplace';

import { IAddToCartParams } from 'models/car-parts.model';
import { addToCartAction } from 'store/actions/cart.actions';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import CartListButton from 'components/buttons/CartListButton/CartListButton';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import Disponibility from 'components/disponibility/Disponibility';
import './CatalogConsumableItem.scss';
import { Marketplaces } from 'utils/constants/applications';
import useHttp from 'utils/hooks/useHttp';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import Spinner from 'components/spinner/Spinner';
import CURRENCY from 'utils/constants/currency';
import {
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from '@datobs/react-image-magnifiers';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import { checkIfImageExists } from 'utils/utils';
import Placeholder from 'assets/images/car-part-placeholder.png';

type Props = {
  data?;
};

const CatalogConsumableItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [cartLoading, setCartLoading] = useState(false);
  const [quantityValue, setQuantityValue] = useState(1);
  const [image, setImage] = useState('');
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();
  const marketplace = getCurrentMarketplace();
  const [contactLoading, setContactLoading] = useState(false);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const http = useHttp();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [searchParams] = useSearchParams();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const [showPrice, setShowPrice] = useState(false);
  const isMecadepotChild = isRetailerMecadepotChild();
  const [isInternalclient, setIsInternalclient] = useState(false);

  const handleAddToCart = () => {

    if (!quantityValue) {
      return;
    }

    setCartLoading(true);

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      consumable_id: props.data.consumable_id,
      price: getPrice(),
      quantity: quantityValue,
    };

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param, ...{ external_cart_id: localStorage.getItem('external_cart_id') }
      };
    }

    params.push(param);
    dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));

    dispatch(addToCartAction(params))
      .catch(() => {
        dispatch(setQuantityToolbar(+quantityValue - +quantityToolbar));
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  function getPrice() {
    if (props.data.price?.price || props.data.price?.price == 0) {
      return props.data.price.price;
    }

    return props.data.price;
  }

  function handleRefClick() {
    let params;

    params = [
      ['consumable_id', props.data.consumable_id],
      ['data_supplier_name', props.data.data_supplier.name],
      ['data_supplier_reference', props.data.data_supplier_reference],
    ];

    if (searchParams.get('vehicle_identifier')) {
      params = [...params, ['vehicle_identifier', searchParams.get('vehicle_identifier')]];
    }

    if (searchParams.get('source')) {
      params = [...params, ['source', searchParams.get('source')]];
    }


    if (vehicle_id) {

      navigate({ pathname: generatePath(`/reliable/rmi/${vehicle_id}/catalogs/consumables/view/:id`, { id: props.data.consumable_id }), search: `?${createSearchParams(params)}` });
    } else {
      navigate({ pathname: generatePath('/consumables/view/:id', { id: props.data.consumable_id }), search: `?${createSearchParams(params)}` });
    }
  }

  function calcLimit() {
    if (props.data.available && typeof props.data.available === 'object') {
      const newLimit: { uv?: string, warehouse_main } = { ...props.data.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return props.data.available;
  }

  function getAvailablityTitle() {
    if (props.data?.available?.warehouse_main) {
      const warehouses = { ...props.data.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach(key => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });


      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      return '';
    }

    if (props.data.available?.quantity) {
      if (props.data.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  function cirrusContactUs() {
    setContactLoading(true);

    const params = {
      source: props.data?.source,
      reference: props.data?.data_supplier_reference_original,
      supplier_id: props.data?.data_supplier?.id,
      product_type: 'consumable'
    };

    http.cirrus.contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  useEffect(() => {
    const img = new Image();

    img.src = props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder-big.png';

    img.onload = () => {

      setImageDimensions({
        width: img.width > 200 ? 200 : img.width,
        height: img.height > 200 ? 200 : img.height,
      });
    };
  }, [props.data.image]);

  function contactUsBtn() {
    if (props.data.available && typeof props.data.available.warehouse_main === 'object') {
      if ((props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity > 0) || (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity === 0) || props.data.available?.warehouse_main.quantity === 0 || getPrice()?.price == 0) {
        return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
      }

      return '';
    }

    return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
  }

  useEffect(() => {
    checkIfImageExists(props.data.image, (exists) => (exists ? setImage(props.data.image) : setImage(Placeholder)));
  }, []);

  useEffect(() => {
    if (!calcLimit() && isMecadepotChild) {
      setDisableCartBtn(true);
    }
  }, [props.data]);

  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  return (
    <div className="catalog-consumable-item">
      <div className="catalog-consumable-item-favorite">
        {/* <FavoriteButton carPart={props.data} /> */}
      </div>
      <div className="consumable-header">
        {/* <MagnifierContainer className="img-zoom-container" autoInPlace>
          <div className="img">
            <MagnifierPreview
              imageSrc={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder.png'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/images/car-part-placeholder-big.png';
              }}
            />
          </div>
          <MagnifierZoom className="img-zoom" style={imageDimensions} imageSrc={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder-big.png'} />
        </MagnifierContainer> */}
        <MagnifierContainer className="img-zoom-container" autoInPlace>
          <div className="img">
            <MagnifierPreview
              imageSrc={image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/car-part-placeholder-big.png';
              }}
            />
          </div>
          <MagnifierZoom className="img-zoom" style={{ height: '200px', width: '200px' }} imageSrc={image} />
        </MagnifierContainer>
        {/* <div className="img">
          <img src={props.data.image || '/images/car-part-placeholder.png'} alt={props.data.subcategory?.name} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping

            currentTarget.src = '/images/car-part-placeholder.png';
          }}
          />
        </div> */}
        <div className="content">
          <div>
            <div className="title" onClick={() => handleRefClick()}>
              {props.data.data_supplier.name}
            </div>
            <div className="description-short">
              {props.data.description}
            </div>
          </div>
          <div className="description">
            {Array.isArray(props.data.tags) ?
              props.data.tags.map((tag) => tag.value && `${tag.name}: ${tag.value}; `) :
              Object.keys(props.data.tags).map((characteristic: string) =>
                String(props.data.tags[characteristic]).trim() && props.data.tags[characteristic] && String(props.data.tags[characteristic]))
            }
          </div>
        </div>
      </div>
      <div className="consumable-footer">
        {props.data.barcode && props.data.barcode.code && (
          <div className="barcode">
            <img src={`${getMarketplaceBaseUrl()}/${props.data.barcode.image}`} alt="barcode" />
            <div className="barcode-number">{props.data.barcode.code}</div>
          </div>
        )}

        <div className="quantity">
          {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
            <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} value={quantityValue} data={props.data} setIsInternalclient={setIsInternalclient} />
          ) : (
            <Disponibility value={quantityValue} limit={props.data.available} title={getAvailablityTitle()} />
          )}
          {marketplace == Marketplaces.RETAILER || marketplace == Marketplaces.PUNCHOUT ?
            calcLimit() ?
              props.data.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} uv={props.data?.available?.uv} />
              : !calcLimit() && props.data.add_to_cart ? <QuantityInput uv={Number(props.data?.available?.uv)} setValue={setQuantityValue} value={quantityValue} /> : null
            :
            props.data.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} />
          }
        </div>
        <div className="cart">
          <div className="cart-price">
            {' '}
            <CarPartPrice currency={currencies.EUR} price={props.data?.price} priceVAT={props.data?.price?.price_with_vat} quantityValue={quantityValue} part />
          </div>
          <div className="cart-button">
            {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient
              ? contactUsBtn() : (
                props.data.add_to_cart &&
                <div className="button">
                  <CartListButton disableCartBtn={disableCartBtn} onClick={handleAddToCart} cartLoading={cartLoading} value={quantityValue} limit={calcLimit()} addToCart={props.data.add_to_cart} price={getPrice()} />
                </div>
              )}
          </div>
          {showPrice ? quantityValue > 1 && getPrice() ? <div className="oem-price_per_unit">P/U: {CURRENCY[user.currency]}{getPrice()}</div> : '' : ''}
        </div>

      </div>
    </div>
  );
};

export default CatalogConsumableItem;
