import React, { useEffect, useState } from 'react';
import Spinner from '../spinner/Spinner';
import { useTranslation } from 'react-i18next';
import './CarPartPrice.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';


type Props = {
  currency: string;
  price?;
  ttc?: boolean;
  loading?: boolean;
  part?: boolean;
  priceVAT?: number;
  quantityValue?
};

const CarPartPrice: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const [showPrice, setShowPrice] = useState(false);

  const showCurrency = () => {
    if (!props.currency) {
      return false;
    }

    if (props?.price?.price > 0 || props?.price > 0) {
      return <span className="price-currency">{props.currency}</span>;
    } else {
      return '-';
    }
  };

  function getPrice() {
    if (typeof props.price === 'object') {
      return props.price?.price ? Number(props.price.price).toFixed(2) : '-';
    } else {
      return props.price ? Number(props.price).toFixed(2) : '-';
    }
  }

  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  return (
    <div className={!props.part ? 'part-view-price' : 'car-part-price'}>
      {props.loading ? (
        <Spinner class="extra-small" />
      ) : (

        showPrice ?
          <div className="oem-price">
            <div className="price notranslate">
              {getPrice() !== '-'
                ? <React.Fragment>
                  {(Number(getPrice()) * (props.quantityValue || 1)).toFixed(2)} {showCurrency()} HT
                </React.Fragment>
                : getPrice()}
            </div>
            {props.priceVAT && Number(props?.priceVAT) ?
              <React.Fragment>
                <div className="divider" />
                <div className="vat-price">
                  {props?.priceVAT ? showCurrency() : ''} {Number(Number(props?.priceVAT) * props.quantityValue).toFixed(2)} TTC
                  {/* <div className="vat-label">
                    {t('_price_with_vat')}
                  </div> */}
                </div>
              </React.Fragment>
              : null}
          </div> : null
      )}

      {props.price && props.ttc ? <span className="price-ttc">TTC</span> : null}
    </div>
  );
};

CarPartPrice.displayName = 'CarPartPrice';

export default CarPartPrice;
