import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import Spinner from 'components/spinner/Spinner';
import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps, TablePropGetter, TableProps } from 'react-table';
import { CartTableItem } from './CartTable.model';
import './CartTable.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { CartTableContext, ICartTableContext } from '../../../../../context/CartTableContext';
import TableMoreInformation from 'components/tables/TableMoreInformation/TableMoreInformation';
import CURRENCY from 'utils/constants/currency';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  rowLoading?: boolean;
  hiddenColumns: string[];
  getTableProps: (propGetter?: TablePropGetter<CartTableItem> | undefined) => TableProps;
  headerGroups: HeaderGroup<CartTableItem>[];
  prepareRow: (row: Row<CartTableItem>) => void;
  rows: Row<CartTableItem>[];
  getTableBodyProps: (propGetter?: TableBodyPropGetter<CartTableItem> | undefined) => TableBodyProps;
  loading: boolean;
  handleCheckout?: () => void;
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteAll?: () => void;
  checkoutLoading?: boolean;
  register?;
  totalPartsPrice: number;
  quantityToolbar: number;
};

const CartTableView: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { isMobile } = useDeviceDetect();
  const { selectAll, selectedData } = useContext(CartTableContext) as ICartTableContext;
  const isMecadepotChild = isRetailerMecadepotChild();

  return (
    <div className="cart-table-container container-fluid-large">
      {props.loading ? (
        <Spinner class="Spinner"></Spinner>
      ) : props.rows.length >= 0 ? (
        <React.Fragment>
          <div className="cart-actions-wrapper">
            <label className="checkbox-container">
              <input type="checkbox" checked={selectAll} onChange={props.handleSelectAll} />
              <span className="link">{t('_select_all')}</span>
            </label>
            {selectedData.length > 0 && (
              <div className="cart-actions-buttons">
                <span className="link" onClick={props.handleDeleteAll}>
                  {t('_delete_selected')}
                </span>
              </div>
            )}
          </div>
          <div className="cart-table-wrapper">
            <table {...props.getTableProps()}>
              <thead className="headings">
                {props.headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={headerGroup.headers[index].id} className="row">
                      {headerGroup.headers.map((column) => {
                        const { key, ...restColumn } = column.getHeaderProps({
                          style: { minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth },
                        });

                        return (
                          <th key={key} {...restColumn} className="heading">
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...props.getTableBodyProps()} className="body">
                {props.rows.map((row) => {
                  props.prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps();

                  //  totalPartsPrice += Number(row.original.price) * Number(row.original.quantity_availability.quantity);

                  return (
                    <React.Fragment key={key}>
                      <tr key={key} {...restRowProps} className="row">
                        {row.cells.map((cell) => {
                          const { key, ...restCellProps } = cell.getCellProps();

                          return (
                            <td key={key} {...restCellProps} className="cell">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded ? TableMoreInformation({ row, hiddenColumns: props.hiddenColumns, extraFieldsNumber: 2 }) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
              <tfoot className="table-footer">
                <tr className="row">
                  <td className="cell" colSpan={isMobile ? 3 : 5}></td>
                  <td className="cell">
                    <span className="total-qty">{quantityToolbar}</span>
                  </td>
                  {isMecadepotChild ?
                    <td className="cell" colSpan={3}>
                      {parseFloat(props.totalPartsPrice.toFixed(2))}{`  ${CURRENCY[user!.currency]}`} HT
                    </td>
                    :

                    <td className="cell" colSpan={3}>
                      {CURRENCY[user!.currency]}  HT {parseFloat(props.totalPartsPrice.toFixed(2))}
                    </td>
                  }
                </tr>
              </tfoot>
            </table>
            <div className="cart-checkout">
              <p className="title">{t('_internal_cmd')}</p>

              <TextInput type="text" {...props.register('order_id')} />

              <div className="checkout-button">
                <DefaultButton
                  disabled={props.rowLoading}
                  isloading={props.checkoutLoading}
                  text={t('_confirm_order')}
                  onClick={props.handleCheckout}
                  red
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        t('_no_items_in_cart')
      )}
    </div>
  );
};

CartTableView.displayName = 'CartTableView';

export default CartTableView;
