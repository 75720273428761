import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useHttp from 'utils/hooks/useHttp';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import { ILoginResponse } from 'utils/hooks/http/auth.http';
import { useAppDispatch } from '../../utils/hooks/redux';
import { login } from '../../store/reducers/useSlices';
import NewLoginView from './NewLogin.view';
import { getCountryFromLocale } from 'utils/locale';
import { useTranslation } from 'react-i18next';
import { getRedirectedHref } from '../../utils/utils';


const NewLogin: React.FC = () => {
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  const [isLoading, setisLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const redirectedHref = getRedirectedHref();
  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const http = useHttp();

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userData')) {
      navigate('/');
    }
  }, []);

  const loginHandler = () => {
    window.location.href = getMarketplaceBaseUrl() + '/social-auth/microsoft';

  };


  const onSubmit = async () => {
    setisLoading(true);

    const values = getValues();

    const param = { password: values.password ?? '', username: values.email ?? '' };

    http.auth
      .login(param)
      .then((res: ILoginResponse) => {
        localStorage.setItem('token', res.token);

        localStorage.setItem('userData', JSON.stringify(res.user));
        dispatch(login(res.user));
        const country = getCountryFromLocale(i18n);

        if (country) {
          const locale = `${res.user.language}-${country.id}`;

          localStorage.setItem('locale', locale);
          i18n.changeLanguage(locale);
        }


        if (redirectedHref) {
          window.location.replace(redirectedHref);
        } else {
          navigate('/');
        }
      })
      .catch((err) => {
        notify(err.response.data.message);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  return (
    <NewLoginView handleSubmit={handleSubmit} onSubmit={onSubmit} register={register} errors={errors} isLoading={isLoading} loginHandler={loginHandler}></NewLoginView>
  );
};

NewLogin.displayName = 'NewLogin';

export default NewLogin;
