import React, { useState } from 'react';
import BarCode from './BarCode/BarCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { currencies } from 'utils/enums/marketplace';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addToCartAction } from 'store/actions/cart.actions';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';

import { RootState } from 'store/store';
import { IAddToCartParams } from 'models/car-parts.model';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import Disponibility from 'components/disponibility/Disponibility';
import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import IconButton from 'components/buttons/IconButton/IconButton';
import Spinner from 'components/spinner/Spinner';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';
import './BatteryOptions.scss';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import useHttp from 'utils/hooks/useHttp';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  battery;
};

const BatteryOptions: React.FC<Props> = (props) => {
  const battery = props.battery;
  const [quantityValue, setQuantityValue] = useState<number>(battery?.available?.uv || 1);
  const [addToCartButtonLoading, setAddToCartButtonLoading] = useState(false);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const http = useHttp();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const currentMarketplace = getCurrentMarketplace();
  const isMecadepotChild = isRetailerMecadepotChild();
  const [isInternalclient, setIsInternalclient] = useState(false);

  const handleAddToCart = () => {
    if (!quantityValue) {
      return;
    }

    setAddToCartButtonLoading(true);

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      battery_id: battery.battery_id,
      quantity: quantityValue,
      price: getPrice(),
    };

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    params.push(param);

    dispatch(addToCartAction(params))
      .then(() => {
        dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));
      })
      .finally(() => {
        setAddToCartButtonLoading(false);
      });
  };

  function getPrice() {

    if (battery.price && typeof battery.price === 'object') {
      if (battery.price?.price) {
        return battery.price.price;
      } else {
        return null;
      }
    }

    return battery.price;
  }

  function calcLimit() {
    if (typeof battery.available === 'object' && 'quantity' in battery.available) {
      return battery.available?.quantity;
    }

    if (battery.available && typeof battery.available === 'object') {
      const newLimit: { uv?: string; warehouse_main } = { ...battery.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return battery.available;
  }

  function getAvailablityTitle() {
    if (battery?.available?.warehouse_main) {
      const warehouses = { ...battery.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach((key) => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }


      if (!getPrice()) {
        return '';
      }

      return '';
    }

    if (battery.available?.quantity) {
      if (battery.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  function disableCart() {
    if (currentMarketplace == Marketplaces.RETAILER || currentMarketplace == Marketplaces.PUNCHOUT) {
      if (!calcLimit() && isMecadepotChild) {
        return true;
      }

      if (Number(calcLimit()) < quantityValue && !battery?.add_to_cart) {
        return true;
      }

      if (!calcLimit() && isMecadepotChild) {
        return true;
      }

      if (addToCartButtonLoading || !quantityValue) {
        return true;
      }

      // if (Number(calcLimit()) === 0 && !getPrice()) {
      //   return true;
      // }

      if (!(calcLimit() && Number(calcLimit()) < quantityValue) && battery?.add_to_cart) {
        return false;
      }
    }
  }

  function cirrusContactUs() {
    setContactLoading(true);
    const params = {
      source: battery?.source,
      reference: battery?.data_supplier_reference_original,
      supplier_id: battery?.data_supplier?.id,
      product_type: 'battery'
    };

    http.cirrus
      .contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  function contactUsBtn() {
    if (battery.available && typeof battery.available.warehouse_main === 'object') {
      if (
        (battery.available?.warehouse_main.quantity === 0 && battery.available?.warehouse_all.quantity > 0) ||
        (battery.available?.warehouse_main.quantity === 0 && battery.available?.warehouse_all.quantity === 0) ||
        battery.available?.warehouse_main.quantity === 0
      ) {
        return (
          <div className="cirrus-contact-us" onClick={cirrusContactUs}>
            {contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}
          </div>
        );
      }

      return '';
    }

    return (
      <div className="cirrus-contact-us" onClick={cirrusContactUs}>
        {contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}
      </div>
    );
  }


  return (
    <div className="battery-options-wrapper">
      <div className="top">
        <BarCode item={battery} />
      </div>

      <div className="availability-add-to-cart-wrapper">
        <div className="bottom">
          <div className="availability">
            {currentMarketplace == Marketplaces.RETAILER || currentMarketplace == Marketplaces.PUNCHOUT ? (
              calcLimit() ? (
                battery.add_to_cart && (
                  <QuantityInput uv={Number(battery?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
                )
              ) : !calcLimit() && battery.add_to_cart ? (
                <QuantityInput uv={Number(battery?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
              ) : null
            ) : (
              battery.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} />
            )}
            {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
              <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} loading={false} value={quantityValue} data={battery} setIsInternalclient={setIsInternalclient} />
            ) : (
              <Disponibility
                loading={false}
                value={quantityValue}
                limit={battery?.available}
                title={getAvailablityTitle()}
                price={battery.price}
              />
            )}
            <CarPartPrice loading={false} currency={currencies.EUR} price={battery?.price} priceVAT={battery?.price?.price_with_vat} quantityValue={quantityValue} />
          </div>
          {isMobile ? (
            <div className="mobile-buttons">
              <div className="add-to-quote-button">
                <IconButton
                  text={t('_add_to_quote')}
                  className="iconButton"
                  icon={<FontAwesomeIcon icon={faFileLines} className="add-to-quote" />}
                ></IconButton>
              </div>
              {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient ? (
                contactUsBtn()
              ) : (
                battery?.add_to_cart && (
                  <div className="add-to-cart-button">
                    <IconButton
                      text={t('_add_to_cart')}
                      disabled={disableCart()}
                      className="iconButton"
                      onClick={handleAddToCart}
                      icon={
                        addToCartButtonLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />
                      }
                    ></IconButton>
                  </div>
                ))}
            </div>
          ) : (
            user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient ? (
              contactUsBtn()
            ) : (
              battery?.add_to_cart && (
                <div className="button">
                  <IconButton
                    text={t('_add_to_cart')}
                    disabled={disableCart()}
                    className="iconButton"
                    onClick={handleAddToCart}
                    icon={
                      addToCartButtonLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />
                    }
                  ></IconButton>
                </div>
              ))
          )}
        </div>
      </div>

    </div>
  );
};

export default BatteryOptions;
