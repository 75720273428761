import { ICatalogModel } from './catalog.model';
import { ReactComponent as Oil } from 'assets/Icons/oil.svg';
import { ReactComponent as OilSvg } from 'assets/Icons/oil_new.svg';
import { ReactComponent as Parts } from 'assets/Icons/parts.svg';
import { ReactComponent as Tyre } from 'assets/Icons/tyre.svg';
import { ReactComponent as Battery } from 'assets/Icons/battery_new.svg';
import { ReactComponent as OilImage } from 'assets/images/catalogs/oil.svg';
import TyreImage from 'assets/images/catalogs/tyre.png';
import { ReactComponent as BatteryImage } from 'assets/images/catalogs/battery.svg';
import { ReactComponent as ConsumableImage } from 'assets/images/catalogs/consumable.svg';
import { ReactComponent as TopSalesImage } from 'assets/images/catalogs/top_sales.svg';
import { FaSprayCan, FaCarBattery, FaAward } from 'react-icons/fa';
import { IOption } from 'interfaces/select';
import { TabCategories } from 'views/Sidebar/Sidebar.model';
import { IUser } from 'models/IUser';

export const catalogItems: ICatalogModel[] = [
  {
    text: '_parts',
    image: Parts,
    link: '',
    tab: TabCategories.Pieces,
  },
  {
    text: '_tyre',
    image: Tyre,
    link: '',
    tab: TabCategories.Pneus,
  },
  {
    text: '_oil',
    image: Oil,
    link: '',
    tab: TabCategories.Huiles,
  },
  {
    text: '_consumables',
    image: FaSprayCan,
    link: '',
    tab: TabCategories.Consumables,
  },
  {
    text: '_battery',
    image: FaCarBattery,
    link: '',
    tab: TabCategories.Battery,
  },
];

export const catalogTypeOptions = (user: IUser): IOption[] => [
  {
    label: '_consumables',
    value: 'consumable',
    available: user.hasConsumable,
  },
  {
    label: '_tyre',
    value: 'tyre',
    available: user.hasTyre,
  },
  {
    label: '_oil',
    value: 'oil',
    available: user.hasOil,
  },
  {
    label: '_battery',
    value: 'battery',
    available: user.hasBattery,
  },
  // {
  //   label: '_non_tec_doc_catalog',
  //   value: 'non_tec_doc_catalog',
  //   available: user.has_nonTecDocCatalog
  // },
];

export const nonTecdocTypeOptions = (user: IUser): IOption[] => [
  {
    label: '_parts',
    value: 'non_tec_doc_catalog',
    available: user.has_nonTecDocCatalog,
  },
];

export const retailerCatalogItems = (user: IUser): ICatalogModel[] => [
  {
    text: '_parts',
    image: Parts,
    mainImage: OilImage,
    link: '',
    tab: TabCategories.Pieces,
    disabled: true,
  },
  {
    text: '_tyre',
    image: Tyre,
    mainImage: TyreImage,
    link: '/catalogs/tyres',
    tab: TabCategories.Pneus,
    disabled: !user?.hasTyre,
  },
  {
    text: '_oil',
    image: OilSvg,
    mainImage: OilImage,
    link: '/catalogs/oils',
    tab: TabCategories.Huiles,
    disabled: !user?.hasOil,
  },
  {
    text: '_consumables',
    image: FaSprayCan,
    mainImage: ConsumableImage,
    link: '/catalogs/consumables',
    tab: TabCategories.Consumables,
    disabled: !user?.hasConsumable,
  },
  {
    text: '_battery',
    image: Battery,
    mainImage: BatteryImage,
    link: '/catalogs/batteries',
    tab: TabCategories.Battery,
    disabled: !user?.hasBattery,
  },
  {
    text: '_top_sales',
    image: FaAward,
    mainImage: TopSalesImage,
    link: '/catalogs/top_sales',
    tab: TabCategories.TopSales,
    disabled: !user?.hasTopSales,
  },
];
