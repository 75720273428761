
import Modal from 'components/modal/Modal';
import Pagination from 'components/pagination/Pagination';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import './Battery.scss';
import { IBattery, IBatteryFilter } from 'interfaces/battery';
import axios from 'axios';
import Create from './ModalCreate/Create';
import { useForm } from 'react-hook-form';
import ModalDelete from '../../../components/CatalogNaf/ModalDelete/ModalDelete';

import ItemEshopBattery from 'components/CatalogNaf/ItemEshopBattery/ItemEshopBattery';
import Edit from './ModalEdit/Edit';
import BatteryFilter from 'components/filter/BatteryFilter/BatteryFilter';
import { notify } from 'utils/marketplace';


const Battery: React.FC = () => {
  const { getValues, reset, setValue, register } = useForm();
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<IBattery[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [forcePage, setForcePage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [battery, setbattery] = useState<number>();
  const cancelToken = axios.CancelToken.source();
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState<IBatteryFilter>();
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
  const [openModalCreate, setOpenModalCreate] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<IBattery[]>([]);
  const [selectedIds, setSelectedIds] = useState<Array<string>>();
  const [linkDeleteScop, setLinkScop] = useState<string>();
  let isMounted = true;
  let isMountedFilter = true;

  const { t, i18n } = useTranslation();

  function feach(page) {
    setSelectAll(false);
    setSelectedData([]);
    setSelectedIds([]);
    reset();
    let urlParams;

    if (Array.from(searchParams).length) {

      let param = {};

      searchParams.forEach((value, key) => {
        if (key === 'page') {
          param = { ...param, [key]: String(page) };
        } else if (key === 'reference') {
          param = { ...param, [key]: String(value) };
        } else {
          param = { ...param, [key.replace('[]', '')]: [String(value)] };
        }
      });

      urlParams = { ...{ page: page }, ...param };
    } else {
      urlParams = { page: page };
    }


    http.batteryAdminHttp.getBatteryAdmin(urlParams, cancelToken.token).then((resp) => {
      setData(resp.data.data);
      setTotalPage(resp.data.total_pages);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }).catch(() => {
      console.log('cancelToken.token!!');
      setLoading(false);
    });
  }

  useEffect(() => {
    if (isMounted) {
      setLoading(true);
      feach(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
      setSubmit(false);
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, submit, searchParams.get('category_ids[]'), searchParams.get('brand_ids[]'), searchParams.get('erp_client_id')]);

  function feachFilter() {
    setLoadingFilter(true);

    Promise.all([
      http.batteryAdminHttp.getBatteryBrand({ per_page: 10000 }),
      http.batteryAdminHttp.getBatteryCategory({ per_page: 10000 })
    ])
      .then((res) => {
        const resultBrand = res[0].data.data.map((item) => {
          return { label: item.name, value: Number(item.id) };
        });

        const resultCategory = res[1].data.data.map((item) => {
          return { label: item.name, value: Number(item.id) };
        });

        const result = {
          brand: resultBrand,
          category: resultCategory,
        };

        setFilter(result);

      })
      .finally(() => {
        setLoadingFilter(false);
      });

  }

  useEffect(() => {
    if (isMountedFilter) {
      setLoadingFilter(true);
      feachFilter();
    }

    return () => {
      isMountedFilter = false;
    };
  }, [i18n.language]);
  const handlePageClick = (page) => {

    setSelectAll(false);
    setSelectedData([]);
    setSelectedIds([]);
    reset();
    setLoading(true);
    feach(page);


  };

  const openModalBattery = (id: number) => {
    setOpenModal(true);
    setbattery(id);

  };


  const deleteItem = (id: number) => {
    setOpenModalDelete(true);
    setbattery(id);
    setLinkScop('Id');
  };


  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!data) {
      return;
    }

    // find the item in the data array by id
    const item = data.find((item) => item.battery_id === id);

    if (!item) {
      return;
    }

    // check if item is already in the selectedData array
    const itemInSelectedData = selectedData.find((item) => item.battery_id === id);

    // if item is not in the selectedData array and the checkbox is checked, add it to the array
    if (isChecked && !itemInSelectedData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedData((prevState) => [...prevState, item]);
    } else if (!isChecked && itemInSelectedData) {
      // if item is in the selectedData array and the checkbox is unchecked, remove it from the array
      const selectedDataFiltered = selectedData.filter((item) => item.battery_id !== id);

      setSelectedData(selectedDataFiltered);
    }


  };

  const handleOnChangeTopOffer = async (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!data) {
      return;
    }

    // find the item in the data array by id
    const item = data.find((item) => item.battery_id === id);

    if (!item) {
      return;
    }

    await http.batteryAdminHttp.postBatteryTopOffer(id, { 'top_offer': isChecked });
  };

  const handleOnChangeTopSales = async (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!data) {
      return;
    }

    // find the item in the data array by id
    const item = data.find((item) => item.battery_id === id);

    if (!item) {
      return;
    }

    await http.batteryAdminHttp.postBatteryTopSales(id, { 'top_sales': isChecked });
  };

  useEffect(() => {
    // if all items are selected, check the select all checkbox

    if (selectedData.length == data.length && data.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedData]);

  const handleSelectAll = (event) => {


    const isChecked = event.target.checked;

    setSelectAll(isChecked);


    if (isChecked) {
      setValue('checkbox-cart', data?.map((item) => String(item.battery_id)));
      setSelectedData(data ?? []);
    } else {
      setValue('checkbox-cart', data && data?.length > 1 ? [] : '');
      setSelectedData([]);

    }
  };

  const handleDeleteSelected = () => {

    let items;

    if (Array.isArray(getValues()['checkbox-cart'])) {
      items = getValues()['checkbox-cart']?.map(Number);
    } else {
      items = [Number(getValues()['checkbox-cart'])];
    }

    if (items.length === 0) {
      notify(t('_cart_select_item'), 'info');

      return;
    }

    setSelectedIds(items);
    setOpenModalDelete(true);
    setLinkScop('selectId');

  };

  const handleDeleteAll = () => {

    setOpenModalDelete(true);
    setLinkScop('selectAll');

  };

  const getLink = () => {

    let link;

    if (linkDeleteScop == 'Id') {
      link = http.batteryAdminHttp.deleteBattery;
    }

    if (linkDeleteScop == 'selectId') {
      link = http.batteryAdminHttp.deleteBatterySelected;
    }

    if (linkDeleteScop == 'selectAll') {
      link = http.batteryAdminHttp.deleteBatteryAll;
    }

    return link;
  };

  useEffect(() => {
    setForcePage(Number(searchParams.get('page') ? searchParams.get('page') : 1));
  }, [searchParams.get('page')]);


  return (
    <div className="battery-container" >
      <div className="body">
        <div className="battery-header">
          <div className="title">
            {t('_customer_catalog')}
          </div>
          <div className="btn-new">
            <button onClick={() => setOpenModalCreate(true)}>  {t('_create_battery')}</button>
          </div>
          <div className="filter">
            <div className="title-filter">
              {t('_filter_desc_battery')}
            </div>
            <div className={`btn-filter ${openFilter ? 'active' : ''}`}>
              <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
            </div>
          </div>
        </div>
        <hr className="hr" />
        <div className="container-header-btn">
          {
            data.length ?
              <div className="checkbox-container-div">
                <label className="checkbox-container">
                  <input type="checkbox" checked={selectAll} onChange={(event) => handleSelectAll(event)} />
                  <span className="link">{selectAll ? t('_deselect_all') : t('_select_all')}</span>
                </label>
              </div> : null
          }
          <div className="div-delete">
            {
              selectedData.length ?
                <div className="btn-delete">
                  <button onClick={() => handleDeleteSelected()}>{t('_delete_selected')}</button>
                </div>
                : null
            }
            {
              data.length && !selectedData.length ?
                <div className="btn-delete">
                  <button onClick={() => handleDeleteAll()}>{t('_delete_all')}</button>
                </div>
                : null
            }
          </div>
        </div>


        <ItemEshopBattery
          data={data}
          loading={loading}
          openModalBattery={openModalBattery}
          deleteItem={deleteItem}
          register={register}
          handleOnChange={handleOnChange}
          handleOnChangeTopOffer={handleOnChangeTopOffer}
          handleOnChangeTopSales={handleOnChangeTopSales}
        />

        {totalPage > 1 && (
          <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={forcePage} />
        )}
      </div>

      <BatteryFilter
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filter={filter}
        setData={setData}
        setLoading={setLoading}
        setTotalPage={setTotalPage}
        loadingFilter={loadingFilter}
        setSelectAll={setSelectAll}
        setSelectedData={setSelectedData}
      />

      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        childrenComponent={<Edit setOpenModal={setOpenModal} battery={battery} setSubmit={setSubmit} />}
      />
      <Modal
        openModal={openModalCreate}
        setOpenModal={setOpenModalCreate}
        childrenComponent={<Create setOpenModalCreate={setOpenModalCreate} setSubmit={setSubmit} />}
      />


      <Modal
        openModal={openModalDelete}
        setOpenModal={setOpenModalDelete}
        childrenComponent={
          <ModalDelete
            setOpenModalDelete={setOpenModalDelete}
            setSubmit={setSubmit}
            id={battery}
            selectedIds={selectedIds}
            link={getLink()}
            setSelectedData={setSelectedData}
          />}
      />


    </div >

  );

};

Battery.displayName = 'Battery';

export default Battery; 