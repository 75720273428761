import React from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import '../AdsModule.scss';
import './InterstitialAd.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  isLoading?: boolean;
  items: IPromotionDetails[];
  slidesPerView?: number | 'auto';
  navigation?: boolean;
  pagination?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  onClick: () => void;
};

const InterstitialAdView: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="ads-module-wrapper intersitial-ad-wrapper">
      <div className="button-continue" onClick={() => props.onClick()}>{t('_continue')}</div>
      <SwiperSlider
        isLoading={props.isLoading}
        navigation={props.navigation}
        pagination={props.pagination}
        autoplay={props.autoplay}
        slidesPerView={props.slidesPerView}
        loop={props.loop}
      >
        {
          props.items.map((item: IPromotionDetails, index: number) => {
            return (
              <SwiperSlide className="swiper-slide" key={index}>
                <div className="ad-container type-intersitital">
                  <div className="ad-image">
                    <img src={item.images_path.desktop ? getMarketplaceBaseUrl() + item.images_path.desktop : '/images/car-part-placeholder.png'} alt="part-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/images/car-part-placeholder.png';
                      }}
                    />
                  </div>
                  <div className="promotion-container-slider">
                    <div className="title">
                      {item.title}
                    </div>
                    <div className="description">
                      {item.description}
                    </div>

                  </div>
                </div>
              </SwiperSlide>
            );
          })
        }
      </SwiperSlider>
    </div>
  );
};

InterstitialAdView.displayName = 'InterstitialAdView';

export default InterstitialAdView;
