import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

import './CartListButton.scss';
import Spinner from '../../spinner/Spinner';
import { getCurrentMarketplace } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';

type Props = {
  onClick?: () => void;
  cartLoading?: boolean;
  value?: number;
  limit?: number | string | { quantity: number };
  addToCart?: boolean
  price?: string
  disabled?: boolean
  disableCartBtn?: boolean
};

const CartListButton: React.FC<Props> = (props: Props) => {
  const value = props?.value ?? 0;
  const currentMarketplace = getCurrentMarketplace();

  function disableCartBtn() {
    if (currentMarketplace == Marketplaces.RETAILER || currentMarketplace == Marketplaces.PUNCHOUT) {
      if (props.disabled) {
        return true;
      }

      if (props.disableCartBtn) {
        return true;
      }

      if (props.limit && Number(props.limit) < value && !props.addToCart) {
        return true;
      }

      if (props.cartLoading || !props.value) {
        return true;
      }

      // if(Number(props.limit) === 0 && !props.price){
      //   return true;
      // }

      if (!(props.limit && Number(props.limit) < value) && props.addToCart) {
        return false;
      }
    }
  }

  return (
    <div className={`round-cart-button ${disableCartBtn() ? 'disable_add_cart' : ''}`}
      onClick={props.onClick}
    >
      {props.cartLoading ? <Spinner class="extra-small round-cart-spinner"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="cart-icon" />}
    </div>
  );
};

CartListButton.displayName = 'CartListButton';

export default CartListButton;
