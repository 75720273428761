
import Pagination from 'components/pagination/Pagination';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useHttp from 'utils/hooks/useHttp';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { INonTecDocPart } from 'interfaces/nonTecdocCatalog';
import NonTecdocPart from 'components/CatalogNaf/NonTecdocPart/NonTecdocPart';
import NonTecdocPartsFilter from 'components/filter/NonTecdocPartsFilter/NonTecdocPartsFilter';
import { ISelectModal } from 'utils/rmi/interface/graphics.interface';
import './NonTecdocParts.scss';
import Modal from 'components/modal/Modal';
import NonTecdocEdit from './ModalEdit/NonTecdocEdit';
import NonTecdocCreate from './ModalCreate/NonTecdocCreate';
import { notify } from 'utils/marketplace';
import ModalDelete from 'components/CatalogNaf/ModalDelete/ModalDelete';


const NonTecdocParts: React.FC = () => {
  const { reset, setValue, register, getValues } = useForm();
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<INonTecDocPart[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [nonTecdocId, setNonTecdocId] = useState<number>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [forcePage, setForcePage] = useState<number>(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
  const [submit, setSubmit] = useState<boolean>(false);
  const cancelToken = axios.CancelToken.source();
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState<ISelectModal[]>();
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<INonTecDocPart[]>([]);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
  const [linkDeleteScop, setLinkScop] = useState<string>();
  let isMounted = true;

  const { t, i18n } = useTranslation();

  function fetchParts(page) {
    setSelectAll(false);
    setLoading(true);
    setSelectedData([]);
    reset();
    let urlParams;

    if (Array.from(searchParams).length) {

      let param = {};

      searchParams.forEach((value, key) => {

        if (key === 'page') {
          param = { ...param, [key]: String(page) };
        } else if (key === 'reference') {
          param = { ...param, [key]: String(value) };
        } else {
          param = { ...param, [key.replace('[]', '')]: [String(value)] };
        }
      });

      urlParams = { ...{ page: page }, ...param };
    } else {
      urlParams = { page: page };
    }


    http.nonTecDocCatalog.getNonTecdocParts(urlParams, cancelToken.token).then((resp) => {
      setData(resp.data.data);
      setTotalPage(resp.data.total_pages);
      setLoading(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }).catch(() => {
      console.log('cancelToken.token!!');
      setLoading(false);
    });
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const id = Number(e.target.value);

    if (!data) {
      return;
    }

    // find the item in the data array by id
    const item = data.find((item) => item.id === id);

    if (!item) {
      return;
    }

    // check if item is already in the selectedData array
    const itemInSelectedData = selectedData.find((item) => item.id === id);

    // if item is not in the selectedData array and the checkbox is checked, add it to the array
    if (isChecked && !itemInSelectedData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedData((prevState) => [...prevState, item]);
    } else if (!isChecked && itemInSelectedData) {
      // if item is in the selectedData array and the checkbox is unchecked, remove it from the array
      const selectedDataFiltered = selectedData.filter((item) => item.id !== id);

      setSelectedData(selectedDataFiltered);
    }
  };


  useEffect(() => {
    // if all items are selected, check the select all checkbox

    if (selectedData.length == data.length && data.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedData]);

  const handleDeleteSelected = () => {
    let items: number[] = [];

    if (Array.isArray(getValues()['checkbox-cart'])) {
      items = getValues()['checkbox-cart']?.map(Number);
    } else {
      items.push(Number(getValues()['checkbox-cart']));
    }

    if (items.length === 0) {
      notify(t('_cart_select_item'), 'info');

      return;
    }

    setSelectedIds(items);
    setOpenModalDelete(true);
    setLinkScop('selectId');
  };

  const handleDeleteAll = () => {
    setOpenModalDelete(true);
    setLinkScop('selectAll');
  };

  const deleteItem = (id: number) => {
    setOpenModalDelete(true);
    setNonTecdocId(id);
    setLinkScop('Id');
  };

  const getLink = () => {
    let link;

    if (linkDeleteScop == 'Id') {
      link = http.nonTecDocCatalog.partDelete;
    }

    if (linkDeleteScop == 'selectId') {
      link = http.nonTecDocCatalog.partDeleteSelected;
    }

    if (linkDeleteScop == 'selectAll') {
      link = http.nonTecDocCatalog.partDeleteAll;
    }

    return link;
  };


  useEffect(() => {
    if (isMounted) {
      fetchParts(searchParams.get('page') ? Number(searchParams.get('page')) : 1);
      setSubmit(false);
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, submit, searchParams.get('category_ids[]'), searchParams.get('brand_ids[]'), searchParams.get('erp_client_id')]);


  const handlePageClick = (page) => {
    setSelectAll(false);
    setSelectedData([]);
    reset();
    setLoading(true);
    fetchParts(page);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;

    setSelectAll(isChecked);

    if (isChecked) {
      setValue('checkbox-cart', data?.map((item) => String(item.id)));
      setSelectedData(data ?? []);
    } else {
      setValue('checkbox-cart', data && data?.length > 1 ? [] : '');
      setSelectedData([]);

    }
  };

  function openNonTecdocModal(id) {
    setNonTecdocId(id);
    setOpenEditModal(true);
  }

  function fetchFilter() {
    setLoadingFilter(true);
    http.nonTecDocCatalog.getNonTecdocSuppliers({ per_page: 10000 }).then((res) => {
      const resultSupplier = res.data.data.map((item) => {
        return { label: item.name, value: Number(item.id) };
      });

      setFilter(resultSupplier);
    })
      .finally(() => {
        setLoadingFilter(false);
      });
  }

  useEffect(() => {
    // if all items are selected, check the select all checkbox
    if (selectedData.length == data.length && data.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedData]);

  useEffect(() => {
    fetchFilter();
  }, []);

  useEffect(() => {
    setForcePage(Number(searchParams.get('page') ? searchParams.get('page') : 1));
  }, [searchParams.get('page')]);

  return (
    <div className="non_tecdoc_parts-container" >
      <div className="body">
        <div className="non_tecdoc_parts-header">
          <div className="title">
            {t('_customer_catalog')}
          </div>
          <div className="btn-new">
            <button onClick={() => setOpenCreateModal(true)}>{t('_create_non_tecdoc_part')}</button>
          </div>
          <div className="filter">
            <div className={`btn-filter ${openFilter ? 'active' : ''}`}>
              <button onClick={() => setOpenFilter(true)}>{t('_filter_result')}</button>
            </div>
          </div>
        </div>
        <hr className="hr" />
        <div className="container-header-btn">
          {
            data.length ?
              <div className="checkbox-container-div">
                <label className="checkbox-container">
                  <input type="checkbox" checked={selectAll} onChange={(event) => handleSelectAll(event)} />
                  <span className="link">{selectAll ? t('_deselect_all') : t('_select_all')}</span>
                </label>
              </div> : null
          }
          <div className="div-delete">
            {
              selectedData.length ?
                <div className="btn-delete">
                  <button onClick={() => handleDeleteSelected()}>{t('_delete_selected')}</button>
                </div>
                : null
            }
            {
              data.length && !selectedData.length ?
                <div className="btn-delete">
                  <button onClick={() => handleDeleteAll()}>{t('_delete_all')}</button>
                </div>
                : null
            }
          </div>
        </div>
        <NonTecdocPart data={data} loading={loading} handleOnChange={handleOnChange} deleteItem={deleteItem} openNonTecdocModal={openNonTecdocModal} register={register} />
        {totalPage > 1 && (
          <Pagination pageCount={totalPage} handlePageClick={handlePageClick} forcePage={forcePage} />
        )}
        <Modal
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          childrenComponent={<NonTecdocEdit setOpenModal={setOpenEditModal} nonTecdocId={nonTecdocId} setSubmit={setSubmit} />}
        />
        <Modal
          openModal={openCreateModal}
          setOpenModal={setOpenCreateModal}
          childrenComponent={<NonTecdocCreate setOpenModal={setOpenCreateModal} setSubmit={setSubmit} />}
        />
        <Modal
          openModal={openModalDelete}
          setOpenModal={setOpenModalDelete}
          childrenComponent={
            <ModalDelete setOpenModalDelete={setOpenModalDelete} setSubmit={setSubmit} id={nonTecdocId}
              selectedIds={selectedIds}
              link={getLink()}
              setSelectedData={setSelectedData}
            />}
        />

        <NonTecdocPartsFilter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          filter={filter}
          setData={setData}
          setLoading={setLoading}
          setTotalPage={setTotalPage}
          loadingFilter={loadingFilter}
          setSelectAll={setSelectAll}
          setSelectedData={setSelectedData}
        />
      </div>
    </div >
  );
};

export default NonTecdocParts; 