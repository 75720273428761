import { ILoginRequest } from 'models/ILoginForm.model';
import { IUser } from '../../../models/IUser';
import { serverAxios } from '../../authHttp';

class AuthHttp {
  private static route = '/login';
  private static publicRoute = '/public-login';
  private static loginAuth2 = '/logingateway';

  public async login(user: ILoginRequest): Promise<ILoginResponse> {
    return serverAxios.post(AuthHttp.route, user);
  }
  public async loginAuth2(): Promise<ILoginResponse> {
    return serverAxios.post(AuthHttp.loginAuth2);
  }
  public async publicLogin(user: { domain: string }): Promise<ILoginResponse> {
    return serverAxios.post(AuthHttp.publicRoute, user);
  }
}

export type ILoginResponse = {
  token: string;
  user: IUser;
};

export default AuthHttp;
