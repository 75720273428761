import React, { useEffect, useState } from 'react';

import QuantityInputView from './QuantityInput.view';
import { MAX_ALLOWED_CAR_PARTS } from '../../../utils/constants/applications';

type Props = {
  onAdd?: () => void;
  onSubstract?: () => void;
  value: number;
  setValue?: (value: number) => void;
  onChange?;
  index?: number | string;
  setValueItems?;
  readonly?: boolean;
  disabled?: boolean;
  red?: boolean
  uv?: number
  disableAddBtn?: boolean
  disableSubBtn?: boolean
  id?: number
  useZero?: boolean
};
const QuantityInput: React.FC<Props> = (props: Props) => {
  const [value, setValue] = useState<number>(props.value);

  useEffect(() => {
    if (props.setValue) {
      props.setValue(value);
    }

    if (props.setValueItems && value != props.value) {
      props.setValueItems({ id: props.id, index: Number(props.index), quantity: Number(value) });
    }
  }, [value]);

  const onChange = (event) => {
    const eventValue = event.target.value;
    let parsedValue = eventValue.trim();

    if (isNaN(parsedValue)) {
      setValue(0);

      return;
    }

    parsedValue = parsedValue.replace(/^0+/, match => match.length > 1 ? '' : '');

    const intValue = parseInt(parsedValue, 10);

    if (parsedValue === '' || intValue < 0) {
      setValue(0);

      return;
    }

    if (eventValue > 0 && eventValue <= MAX_ALLOWED_CAR_PARTS) {
      if (eventValue === 0) {
        if (props.uv) {
          setValue(0);
        } else {
          setValue(1);
        }
      } else {
        if (props.uv) {
          setValue(Number(Math.ceil(eventValue / props.uv) * props.uv));
        } else {
          setValue(Math.round(Number(eventValue)));
        }

      }
    }

    if (props.setValue) {
      props.setValue(value);
    }

    if (props.onChange) {
      props.onChange;
    }
  };

  const onAdd = () => {
    if (props.disabled) { return; }

    setValue((prevState) => {
      if (prevState <= MAX_ALLOWED_CAR_PARTS) {
        if (props.onAdd) {
          props.onAdd();

        }

        if (props.uv) {
          return Number(prevState) + (1 * props.uv);
        } else {
          return Number(prevState) + 1;
        }

      }

      return prevState;
    });
  };

  const onSubstrat = () => {
    if (props.disabled) { return; }

    setValue((prevState) => {
      if (prevState > 1) {
        if (props.onSubstract) {
          props.onSubstract();
        }

        if (props.uv) {
          return Number(prevState) - (1 * props.uv);
        } else {
          return Number(prevState) - 1;
        }
      }

      if (prevState === 0) {
        return 0;
      }

      if (prevState === 1) {
        return props.useZero ? 0 : 1;
      }

      return 1;
    });
  };

  return <QuantityInputView disableSubBtn={props.disableSubBtn} disableAddBtn={props.disableAddBtn} disabled={props.disabled} red={props.red} readonly={props.readonly} onChange={onChange} value={value} onAdd={onAdd} onSubstract={onSubstrat}></QuantityInputView>;
};

QuantityInput.displayName = 'QuantityInput';

export default QuantityInput;
