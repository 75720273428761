import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getCurrentMarketplace, getMarketplaceBaseUrl, notify } from 'utils/marketplace';

import { IAddToCartParams } from 'models/car-parts.model';
import { addToCartAction } from 'store/actions/cart.actions';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';

import CartListButton from 'components/buttons/CartListButton/CartListButton';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import Disponibility from 'components/disponibility/Disponibility';
import { Marketplaces } from 'utils/constants/applications';
import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import CURRENCY from 'utils/constants/currency';
import {
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from '@datobs/react-image-magnifiers';
import './CatalogOilItem.scss';
import Modal from 'components/modal/Modal';
import ItemViewModal from '../../ItemViewModal/ItemViewModal';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import { checkIfImageExists } from 'utils/utils';
import Placeholder from 'assets/images/car-part-placeholder.png';

type Props = {
  data?;
};

const CatalogOilItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [cartLoading, setCartLoading] = useState(false);
  const [quantityValue, setQuantityValue] = useState(props.data.available.uv || 1);
  const [image, setImage] = useState('');
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();
  const marketplace = getCurrentMarketplace();
  const [contactLoading, setContactLoading] = useState(false);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const http = useHttp();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const [showPrice, setShowPrice] = useState(false);
  const [isInternalclient, setIsInternalclient] = useState(false);
  const [searchParams] = useSearchParams();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const [openModal, setOpenModal] = useState(false);
  const isMecadepotChild = isRetailerMecadepotChild();

  const handleAddToCart = () => {

    if (!quantityValue) {
      return;
    }

    setCartLoading(true);

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      oil_id: props.data.oil_id,
      price: getPrice(),
      quantity: quantityValue,
    };

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param, ...{ external_cart_id: localStorage.getItem('external_cart_id') }
      };
    }

    params.push(param);
    dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));

    dispatch(addToCartAction(params))
      .catch(() => {
        dispatch(setQuantityToolbar(+quantityValue - +quantityToolbar));
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  function getPrice() {
    if (props.data.price?.price) {
      return props.data.price.price;
    }

    return props.data.price;
  }

  function handleRefClick() {

    let params;

    params = [
      ['oil_id', props.data.oil_id],
      ['data_supplier_name', props.data.data_supplier.name],
      ['data_supplier_reference', props.data.data_supplier_reference],
    ];

    if (searchParams.get('vehicle_identifier')) {
      params = [...params, ['vehicle_identifier', searchParams.get('vehicle_identifier')]];
    }

    if (searchParams.get('source')) {
      params = [...params, ['source', searchParams.get('source')]];
    }

    if (vehicle_id) {

      navigate({ pathname: generatePath(`/reliable/rmi/${vehicle_id}/catalogs/oils/view/:id`, { id: props.data.oil_id }), search: `?${createSearchParams(params)}` });
    } else {
      navigate({
        pathname: generatePath('/oils/view/:id', {
          id: props.data.oil_id,
        }),
        search: `?${createSearchParams(params)}`,
      });
    }

  }

  function calcLimit() {
    if (props.data.available && typeof props.data.available === 'object') {
      const newLimit: { uv?: string, warehouse_main } = { ...props.data.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return props.data.available;
  }

  function getAvailablityTitle() {
    if (props.data?.available?.warehouse_main) {
      const warehouses = { ...props.data.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach(key => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });


      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      return '';
    }

    if (props.data.available?.quantity) {
      if (props.data.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  function cirrusContactUs() {
    setContactLoading(true);

    const params = {
      source: props.data?.source,
      reference: props.data?.data_supplier_reference_original,
      supplier_id: props.data?.data_supplier?.id,
      product_type: 'oil'
    };

    http.cirrus.contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  useEffect(() => {
    checkIfImageExists(props.data.image, (exists) => (exists ? setImage(props.data.image) : setImage(Placeholder)));
  }, []);

  function contactUsBtn() {
    if (props.data.available && typeof props.data.available.warehouse_main === 'object') {
      if ((props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity > 0) || (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity === 0) || props.data.available?.warehouse_main.quantity === 0 || getPrice()?.price == 0) {
        return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
      }

      return '';
    }

    return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
  }

  useEffect(() => {
    if (!calcLimit() && isMecadepotChild) {
      setDisableCartBtn(true);
    }
  }, [props.data]);

  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  return (
    <div className="catalog-oil-item">
      <div className="catalog-oil-item-favorite">
        {/* <FavoriteButton carPart={props.data} /> */}
      </div>
      <div className="oil-header">
        <MagnifierContainer className="img-zoom-container" autoInPlace>
          <div className="img">
            <MagnifierPreview
              imageSrc={image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = '/images/car-part-placeholder-big.png';
              }}
            />
          </div>
          <MagnifierZoom className="img-zoom" style={{ height: '200px', width: '200px' }} imageSrc={image} />
        </MagnifierContainer>
        <div className="content">
          <div className="oil-category-name">
            {props.data.tags?.category}
          </div>
          {isRetailerMecadepotChild() ?
            <div className="oil-title" onClick={() => setOpenModal(true)}>
              {props.data.data_supplier.name} {props.data.tags?.viscosity}
            </div>
            :
            <div className="oil-title" onClick={() => handleRefClick()}>
              {props.data.data_supplier.name} {props.data.tags?.viscosity}
            </div>
          }
          <div className="oil-description">
            {props.data.description}
          </div>
          <div className="oil-quantity"><span>{t('_quantity').toLocaleLowerCase()}:</span> {props.data.tags.quantity}</div>
          {/* <div className="description">{Array.isArray(props.data.tags) ? props.data.tags.map((tag) => tag.value && `${tag.name}: ${tag.value}; `) : Object.keys(props.data.tags).map((characteristic: string) => String(props.data.tags[characteristic]).trim() && props.data.tags[characteristic] &&  `${String(t(`_oil_${characteristic}`))}: ${String(props.data.tags[characteristic])}; `)}</div> */}
        </div>
      </div>
      <div className="oil-footer">
        {props.data.barcode && props.data.barcode.code && (
          <div className="barcode">
            <img src={`${getMarketplaceBaseUrl()}/${props.data.barcode.image}`} alt="barcode" />
            <div className="barcode-number">{props.data.barcode.code}</div>
          </div>
        )}


        <div className="quantity">
          {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
            <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} value={quantityValue} data={props.data} setIsInternalclient={setIsInternalclient} />
          ) : (
            <Disponibility value={quantityValue} limit={props.data.available} title={getAvailablityTitle()} />
          )}
          {marketplace == Marketplaces.RETAILER || marketplace == Marketplaces.PUNCHOUT ?
            calcLimit() ?
              <QuantityInput uv={props.data.available?.uv} setValue={setQuantityValue} value={quantityValue} /> :
              !calcLimit() && props.data.add_to_cart ? <QuantityInput uv={Number(props.data?.available?.uv)} setValue={setQuantityValue} value={quantityValue} /> : null
            :
            <QuantityInput setValue={setQuantityValue} value={quantityValue} />
          }
        </div>
        <div className="cart">
          <div className="cart-price">
            <CarPartPrice currency={CURRENCY[user.currency]} price={props.data?.price} priceVAT={props.data?.price?.price_with_vat} quantityValue={quantityValue} part />
          </div>
          <div className="cart-button">
            {/* <div className="button">
              <FileButton />
            </div> */}
            {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient ? contactUsBtn() : (
              props.data.add_to_cart &&
              <div className="button">
                <CartListButton disableCartBtn={disableCartBtn} onClick={handleAddToCart} cartLoading={cartLoading} value={quantityValue} limit={calcLimit()} addToCart={props.data.add_to_cart} price={getPrice()} />
              </div>
            )}
          </div>
          {showPrice ? quantityValue > 1 && getPrice() ? <div className="oem-price_per_unit">P/U: {CURRENCY[user.currency]}{getPrice()}</div> : '' : ''}
        </div>
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={<ItemViewModal setOpenModal={setOpenModal} data={props.data} />}
      />
    </div>
  );
};

export default CatalogOilItem;
