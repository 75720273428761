import React, { useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Head from 'components/tables/Cells/Head/Head';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import i18n from 'i18n';
import Pagination from 'components/pagination/Pagination';
import useHttp from 'utils/hooks/useHttp';
import { ICheckEquivalenceList, IResponseList } from 'utils/hooks/http/check-equivalence.http';
import DefaultTable from 'components/tables/DefaultTable/DefaultTable';
import './CheckEquivalencesList.scss';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { notify } from 'utils/marketplace';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setLoadingTableDlrnHernrCodfouByspecifyingReference } from 'store/slices/dlrnHernrCodfouByspecifyingReference.slice';


type Props = {
  per_page?: number;
  noPaginate?: boolean;
  buttonList?: boolean;
};


const CheckEquivalencesList: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const [dataResponse, setDataResponse] = useState<IResponseList>();
  const [data, setData] = useState<ICheckEquivalenceList[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const http = useHttp();
  const dispatch = useDispatch();
  const { loadingTableDlrnHernrCodfouByspecifyingReference } = useSelector((state: RootState) => state.dlrnHernrCodfouByspecifyingReference);

  const fetchEquivalenceList = (params) => {
    setLoading(true);

    http.checkEquivalenceHttp.getEquivalenceList(params)
      .then((res) => {
        setDataResponse(res.data);
        setData(res.data.data);

      })
      .finally(() => {
        setLoading(false);

      });

  };

  useEffect(() => {

    const params = {
      per_page: props?.per_page ? props.per_page : 20,
      page,
    };

    fetchEquivalenceList(params);

  }, [page]);


  useEffect(() => {

    if (loadingTableDlrnHernrCodfouByspecifyingReference) {
      const params = {
        per_page: props?.per_page ? props.per_page : 20,
        page,
      };

      fetchEquivalenceList(params);
      dispatch(setLoadingTableDlrnHernrCodfouByspecifyingReference(false));

    }

  }, [loadingTableDlrnHernrCodfouByspecifyingReference]);

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };


  function fileExists(url) {
    return fetch(url, { method: 'HEAD' })
      .then(response => response.ok)
      .catch(() => false);
  }

  function download(url) {
    if (!url) {
      notify(t('URL_NOT_EXIST'), 'error');

      return;
    }

    fileExists(url).then(exists => {
      if (exists) {
        const link = document.createElement('a');

        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        notify(t('FILE_NOT_FOUND'), 'error');
      }
    });
  }


  function show(id: string, type) {
    document.getElementById(String(id))!.style.display = type;
  }

  const columns = useMemo(
    (): Column<ICheckEquivalenceList>[] => [
      {
        Header: <Head text={t('_id').toLocaleUpperCase()}></Head>,
        accessor: 'id',
      },
      {
        Header: <Head text={t('_provider').toLocaleUpperCase()}></Head>,
        accessor: 'hernr',
      },

      {
        Header: <Head text={t('_provider').toLocaleUpperCase() + ' ' + t('_equivalent').toLocaleUpperCase()}></Head>,
        accessor: 'hernr_eq',
      },
      {
        Header: <Head text={t('_type').toLocaleUpperCase()}></Head>,
        accessor: 'type',
      },
      {
        Header: <Head text={t('_created_by').toLocaleUpperCase()}></Head>,
        accessor: 'created_by',

      },
      {
        Header: t('_action').toString().toLocaleUpperCase(),

        Cell: (cell) =>

          <div className="action">
            <span>
              <FontAwesomeIcon icon={faFileDownload}
                className="faFileDownload"
                onClick={() => download(cell.row.original.filepath)}
                onMouseEnter={() =>
                  show(String('download' + cell.row.original.id), 'block')
                }
                onMouseLeave={() =>
                  setTimeout(() => {
                    show(String('download' + cell.row.original.id), 'none');
                  }, 100)
                }
              />
              <div
                id={String('download' + cell.row.original.id)}
                className="tooltip_lable"
              >
                {t('_download')}
              </div>
            </span>
          </div>


        ,
        accessor: 'filepath',
        width: 60,
      },
    ],
    [i18n.language, isMobile, isTablet, isDesktop],
  );


  return (
    <div className="check-equivalences-list">

      <div className="check-equivalences-list-title">
        {t('_last_check_equivalences')}
      </div>

      <DefaultTable columns={columns} data={data} loadingContent={loading} />

      <div>
        {dataResponse && dataResponse.total_pages! > 1 && !props.noPaginate && (
          <Pagination pageCount={dataResponse.total_pages} handlePageClick={handlePageClick} forcePage={dataResponse.page} />
        )}
      </div>
      {(props.buttonList && data.length) ? <div className="buyer-table-button">
        <div className="button">
          <DefaultButton text={t('_last_check_equivalences')} onClick={() => navigate('/automotor-online/check-equivalences')} />
        </div>
      </div> : null
      }

    </div>

  );
};


CheckEquivalencesList.displayName = 'CheckEquivalencesList';

export default CheckEquivalencesList;
