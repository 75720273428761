import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
import UniversalPartsNavbar from 'components/navbar/universal-parts-navbar/UniversalPartsNavbar';
import { IBodyResponseConsumable, IConsumableCatalogFilter } from 'interfaces/consumable';
import ProductsViewMode from '../../../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../../../components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_CONSUMABLE } from '../../../../components/ProductsViewModeChanger/products-view-mode-changer-utils';
import axios from 'axios';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import List from 'components/List/List';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import './CatalogsConsumablesPage.scss';
import CatalogConsumableFilter from 'applications/retailer/views/CatalogConsumables/CatalogConsumableFilter/CatalogConsumableFilter';

const CatalogsConsumablesPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const http = useHttp();
  const { search } = useLocation();

  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseConsumable>();
  const [filterData, setFilterData] = useState<IConsumableCatalogFilter>();

  const [consumableCategories, setConsumableCategories] = useState<ICategory[]>([]);
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : consumableCategories[0]?.id });

  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')) ? Number(searchParams.get('page')) : 1);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const cancelToken = axios.CancelToken.source();

  function getConsumableData(data) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      source: searchParams.get('source'),
      vehicle_identifier: searchParams.get('vehicle_identifier'),
      vin: searchParams.get('vin'),
      vehicle_id,
      page,
    };

    const validParams = validateParams(params);

    http.consumableCatalogHttp
      .getConsumables(validParams, cancelToken.token)
      .then((res) => {
        setRefData(res.data);
        setLoading(false);
        setTimeout(() => {
          if (params.page) {
            const firstChild = document.querySelector('.mecadepot-catalog-item');

            if (firstChild) {
              firstChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          }
        }, 100);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        }
      });
  }

  function getConsumableCategories() {
    setLoadingCategories(true);
    http.catalogs.getCarConsumableAllCategories({}).then((res) => {
      setConsumableCategories(res);
    }).finally(() => setLoadingCategories(false));
  }

  function getConsumableFilter() {
    const filterParams = {
      source: searchParams.get('source'),
      vehicle_identifier: searchParams.get('vehicle_identifier'),
      vin: searchParams.get('vin'),
      vehicle_id,
      page,
    };

    const validFilterParams = validateParams(filterParams);

    http.consumableCatalogHttp.getConsumableFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  // const handlePrevPageClick = () => {
  //   if (Number(searchParams.get('page')) > 1) {
  //     setPage((prev) => prev - 1);
  //     searchParams.set('page', String(page - 1));
  //     setSearchParams(searchParams);
  //   }
  // };

  // const handleNextPageClick = () => {
  //   if (refData) {
  //     if (refData?.total_pages && Number(searchParams.get('page')) < refData?.total_pages) {
  //       setPage((prev) => prev + 1);
  //       searchParams.set('page', String(page + 1));
  //       setSearchParams(searchParams);
  //     }
  //   }
  // };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };


  const onCategoryClick = (category) => {
    setActiveCategory({ id: category.id });
    searchParams.set('category_id', String([category.id]));
    searchParams.delete('page');
    setSearchParams(searchParams);
    setPage(1);
  };

  useEffect(() => {
    if (consumableCategories.length) {
      const firstValidCategory = consumableCategories.find((category) => category.number_of_products !== 0);

      setActiveCategory({ id: Number(searchParams.get('category_id')) || Number(firstValidCategory?.id) });
      const params = {
        category_ids: searchParams.get('category_id') ? searchParams.getAll('category_id') : [String(firstValidCategory?.id)],
        brand_ids: searchParams.getAll('brand_id'),
      };

      const validParams = validateParams(params);

      getConsumableData(validParams);
      getConsumableFilter();
    }

    return () => {
      cancelToken.cancel();
    };

  }, [
    i18n.language,
    page,
    search,
    consumableCategories.length,
  ]);

  useEffect(() => {
    getConsumableCategories();
  }, [i18n.language]);


  useEffect(() => {
    if (searchParams.get('category_id')) {
      setActiveCategory({ id: Number(searchParams.get('category_id')) });
    }
  }, [consumableCategories, searchParams.get('category_id')]);

  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  return (
    <div className="consumables-catalog-wrapper">
      <UniversalPartsNavbar option={filterOptionsElement()} title="_find_neccessary_parts" />
      <div className="separator"></div>

      <div className="filterOptions">
        {refData?.data?.length && refData?.data?.length > 0 ? <SortBy /> : null}
        <div className="results">
          {refData?.data?.length ? refData?.data?.length : 0} {t('_results')}
        </div>
        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
      </div>

      <div className="catalog-consumables-items-container">
        <div className="catalog-consumables-sidebar">
          {consumableCategories && consumableCategories.length ? (
            <List
              content={consumableCategories?.map((item) => {
                return { ...item, id: item.id, name: item.name };
              })}
              images={consumableCategories?.map((item) => item.image)}
              itemClassName="parts-list"
              itemId="id"
              onItemClick={onCategoryClick}
              activeCategory={activeCategory}
            />
          ) : null}
        </div>
        <div className="wrapperItems">
          {loadingCategories || loading ? (
            <Spinner class="car-parts-spinner" />
          ) : refData?.data?.length ? (
            <ProductsViewModeChanger catalog={CATALOG_CONSUMABLE} viewMode={viewMode} data={refData?.data} />
          ) : (
            <div className="no-parts-data">
              <div className="notFoundSvg">
                <PartsNotFoundImage />
              </div>
              <h1>{t('_consumables_not_found')}</h1>
            </div>
          )}
          {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && !loading ? (
            <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={page} />
          ) : (
            ''
          )}
        </div>
      </div>

      <CatalogConsumableFilter getData={getConsumableData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default CatalogsConsumablesPage;
