
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';

class PriceMatrixHttp {
  private static listRoute = '/price-matrix/list';
  private static itemById = '/price-matrix/:id/info';
  private static saveRoute = '/price-matrix';
  private static deleteRoute = '/price-matrix/:id';
  private static updateRoute = '/price-matrix/:id';
  private static group = '/price-matrix/get_group/:code';
  private static deleteSelected = '/price-matrix/list';

  public getPriceMatrix(params: { page: number }): Promise<IHttpResponse<IResponse>> {
    return serverAxios.get(PriceMatrixHttp.listRoute, { params });
  }

  public getPriceMatrixById(id: number): Promise<IHttpResponse<IPriceMatrixDetails>> {
    return serverAxios.get(addRouteParam(PriceMatrixHttp.itemById, { id }));
  }

  public editPriceMatrix(data: IPriceMatrixParams, id: number): Promise<IHttpResponse<IPriceMatrixDetails>> {
    return serverAxios.post(addRouteParam(PriceMatrixHttp.updateRoute, { id }), data);
  }

  public savePriceMatrix(data: FormData): Promise<IHttpResponse<{}>> {
    return serverAxios.post(PriceMatrixHttp.saveRoute, data);
  }

  public deletePriceMatrix(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(addRouteParam(PriceMatrixHttp.deleteRoute, { id }));
  }
  public deletePriceMatrixSelected(ids: Array<string>): Promise<IHttpResponse<null>> {
    return serverAxios.delete(PriceMatrixHttp.deleteSelected, { data: { ids: ids } });
  }
  public getGroup(code: string): Promise<IHttpResponse<string[]>> {
    return serverAxios.get(addRouteParam(PriceMatrixHttp.group, { code }));
  }
}

export interface IResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<IPriceMatrixDetails>;
}

export interface IPriceMatrixDetails {
  id: number;
  client: { numcli: number; name: string };
  itgr: { codfou: string; group: string };
  taux: string;
}

export interface IPriceMatrixParams {
  numcli?: number;
  codfou?: string;
  group?: string;
  taux?: number;
}

export default PriceMatrixHttp;
