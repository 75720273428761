import {
  faBatteryCar,
  faBoxOpen,
  faChartBar,
  faCircleXmark,
  faCoins,
  faComment,
  faCubesStacked,
  faEuroSign,
  faLayerGroup,
  faList,
  faNewspaper,
  faOilCan,
  faPercent,
  faPrescriptionBottle,
  faScrewdriverWrench,
  faSearch,
  faStore,
  faTextWidth,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import TechnicalHelpAutomotor from 'components/dropdowns/TechnicalHelpAutomotor/TechnicalHelpAutomotor';
import { INavbarItem } from './Navbar.model';
import { IUser } from 'models/IUser';


export const navbarEspaceClientElements = (user: IUser | null): INavbarItem[] => [
  {
    id: 1,
    text: '_my_clients',
    icon: faUsers,
    link: '/espace-clients/my-clients',
  },
  {
    id: 2,
    text: '_orders',
    icon: faBoxOpen,
    link: '/espace-clients/orders-clients',
  },
  {
    id: 3,
    text: '_my_eshop',
    icon: faStore,
    link: '/espace-clients/my-eshop',
  },
  {
    id: 4,
    text: '_messages',
    icon: faComment,
    link: '/espace-clients/messages',
  },
  {
    id: 23,
    text: '_promotions',
    icon: faPercent,
    link: '/espace-clients/promotions',
  },
  {
    id: 6,
    text: '_statistics',
    icon: faChartBar,
    link: '/espace-clients/statistics',
  },
  {
    id: 7,
    text: '_scrolling_text',
    icon: faTextWidth,
    hidden: user?.client_id
      ? user?.client_id != Number(process.env.REACT_APP_MECADEPOT_NUMCLI)
      : user?.numcli != Number(process.env.REACT_APP_MECADEPOT_NUMCLI),
    link: '/espace-clients/mecadepot_info',
  },
];

export const navbarEspaceCatalogElements: INavbarItem[] = [
  {
    id: 5,
    text: 'Rapid search',
    icon: faSearch,
    link: '/universal-catalog',
  },
  {
    id: 6,
    text: 'Catalog',
    icon: faList,
    link: 'catalogs',
    localStorage: true,
  },
  {
    id: 7,
    text: 'Technical help',
    icon: faScrewdriverWrench,
    link: null,
    localStorage: true,
    component: TechnicalHelpAutomotor,
  },
  {
    id: 8,
    text: 'News',
    icon: faNewspaper,
    link: '/news',
  },
];

export const navbarEspaceSupplierElements: INavbarItem[] = [
  {
    id: 9,
    text: '_my_suppliers',
    icon: faCubesStacked,
    link: '/espace-suppliers/my-suppliers',
  },
  {
    id: 10,
    text: '_orders',
    icon: faBoxOpen,
    link: '/espace-suppliers/orders',
  },
  {
    id: 11,
    text: '_leftovers',
    icon: faCircleXmark,
    link: null,
    disabled: true,
  },
  {
    id: 12,
    text: '_espace_tariffs',
    icon: faEuroSign,
    link: '/espace-suppliers/espace-tariffs',
  },
];

export const navbarEspaceCatalogNafOil: INavbarItem[] = [
  {
    id: 13,
    text: '_my_eshop_oil',
    icon: faOilCan,
    link: '/espace-clients/my-eshop/catalog_oil',
  },
  {
    id: 14,
    text: '_my_eshop_oil_brand',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_oil/oil_brand',
  },
  {
    id: 29,
    text: '_my_eshop_oil_category',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_oil/oil_category',
  },
];

export const navbarEspaceCatalogNafTyre: INavbarItem[] = [
  {
    id: 15,
    text: '_my_eshop_tyre',
    icon: faCoins,
    link: '/espace-clients/my-eshop/catalog_tyres',
  },
  {
    id: 16,
    text: '_my_eshop_tyre_brand',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_tyres/tyres_brand',
  }
];

export const navbarEspaceCatalogNafConsumable: INavbarItem[] = [
  {
    id: 17,
    text: '_my_eshop_consumable',
    icon: faPrescriptionBottle,
    link: '/espace-clients/my-eshop/catalog_consumable',
  },
  {
    id: 18,
    text: '_my_eshop_consumable_brand',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_consumable/consumable_brand',
  },
  {
    id: 19,
    text: '_my_eshop_consumable_category',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_consumable/consumable_category',
  },
];

export const navbarEspaceCatalogNafBattery: INavbarItem[] = [
  {
    id: 20,
    text: '_my_eshop_battery',
    icon: faBatteryCar,
    link: '/espace-clients/my-eshop/catalog_battery',
  },
  {
    id: 21,
    text: '_my_eshop_battery_brand',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_battery/battery_brand',
  },
  {
    id: 22,
    text: '_my_eshop_battery_category',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_battery/battery_category',
  },
];

export const navbarEspaceStockManagement: INavbarItem[] = [
  {
    id: 23,
    text: '_stock_management_parts',
    icon: faLayerGroup,
    link: '/espace-clients/stock-management/parts',
  },
  // {
  //   id: 24,
  //   text: '_stock_price_management',
  //   icon: faMoneyCheckDollar,
  //   link: '/espace-clients/stock-management/price',
  // },
  {
    id: 24,
    text: '_stock_management_oil',
    icon: faLayerGroup,
    link: '/espace-clients/stock-management/oil',
    disabled_type: 'hasOil',
  },
  {
    id: 25,
    text: '_stock_management_tyre',
    icon: faLayerGroup,
    link: '/espace-clients/stock-management/tyre',
    disabled_type: 'hasTyre',
  },
  {
    id: 26,
    text: '_stock_management_battery',
    icon: faLayerGroup,
    link: '/espace-clients/stock-management/battery',
    disabled_type: 'hasBattery',
  },
  {
    id: 27,
    text: '_stock_management_consumable',
    icon: faLayerGroup,
    link: '/espace-clients/stock-management/consumable',
    disabled_type: 'hasConsumable',
  },
  {
    id: 28,
    text: '_stock_management_parts_nonTecDoc',
    icon: faLayerGroup,
    link: '/espace-clients/stock-management/non_tecdoc_parts',
    disabled_type: 'has_nonTecDocCatalog',
  },
];

export const navbarEspaceCatalogNonTecdoc: INavbarItem[] = [
  {
    id: 25,
    text: '_non_tecdoc_parts',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_non_tecdoc',
  },
  {
    id: 26,
    text: '_non_tecdoc_suppliers',
    icon: faList,
    link: '/espace-clients/my-eshop/catalog_non_tecdoc/suppliers',
  },
];
