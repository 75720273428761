import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from './redux';
import { login } from '../../store/reducers/useSlices';
import useHttp from './useHttp';
import { notify } from 'utils/marketplace';
import { useTranslation } from 'react-i18next';

const useAuthSocial = (): void => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const http = useHttp();
  const { t } = useTranslation();

  async function setUserInfo() {
    const user = await http.user.infoUser();

    localStorage.setItem('userData', JSON.stringify(user.data));
    dispatch(login(user.data));
  }

  useEffect(() => {


    if (searchParams.get('access_token')) {
      localStorage.setItem('token', String(searchParams.get('access_token')));
      searchParams.delete('access_token');
      setUserInfo();
    }

    if (searchParams.get('_msg')) {
      console.log('searchParams.get(_msg)', searchParams.get('_msg'));
      notify(t('_' + searchParams.get('_msg')), 'error');
      searchParams.delete('_msg');
      setSearchParams(searchParams);
    }

  }, [searchParams.get('access_token'), searchParams.get('_msg')]);

};

export default useAuthSocial;
