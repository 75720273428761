import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { components, ValueContainerProps } from 'react-select';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';


type Props = {
  setOpenModal: (e: boolean) => void;
  setParams;
  setForcePage: (forcePage: number) => void;
  setIsActive: (e: boolean) => void;
  clients
  providers
  commercialZones
  countries
}

const PriceManagementFilter: React.FC<Props> = (props) => {
  const { isMobile } = useDeviceDetect();
  const { register, getValues, handleSubmit, control, setValue, watch, resetField } = useForm();
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [clientSelected, setClientSelected] = useState<boolean>(false);

  const onSubmit = () => {
    const values = getValues();

    const params = {
      group: values.group,
      numcli: [] as Array<string>,
      codfou: getValues('codfou.value'),
      page: 1
    };

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    if (Object.keys(values).length == 0) {
      return;
    }

    if (watch('type_form.value') === '1') {
      const clients = values.numcli;

      clients?.forEach(({ value }: { value: string }) => {
        params.numcli.push(value);
      });
    }

    if (watch('type_form.value') === '2') {
      const ids: Array<string> = [];

      props.clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.label === client.country) {
            ids.push(client.value);
          }
        });
      });


      ids.forEach((id) => {
        params.numcli.push(id);
      });
    }

    if (watch('type_form.value') === '3') {
      const ids: Array<string> = [];

      props.clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.value === client.zone_commercial) {
            ids.push(client.value);
          }
        });
      });

      ids.forEach((id) => {
        params.numcli.push(id);
      });
    }

    props.setParams(params);
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const resetFilter = () => {
    const values = getValues();

    Object.keys(values).forEach((key) => {
      if (values[key] == undefined || values[key] == '') {
        delete values[key];
      }
    });

    setValue('codfou', null);
    setValue('type_form', null);
    setValue('group', '');
    setValue('numcli', null);

    props.setParams({ page: 1 });
    props.setForcePage(1);
    props.setIsActive(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    }
  };


  useEffect(() => {
    if (props.clients.length) {
      setValue('type_form', { value: '1', label: String(t('_by_client')) });
    }

  }, [props.clients]);

  useEffect(() => {
    if (!clientSelected && props.clients.length) {
      setValue('numcli', { value: user?.client_id, label: user?.client_name });
      setClientSelected(true);
    } else {
      setValue('numcli', null);
    }


  }, [watch('type_form.value')]);

  const CheckboxOption = (props) => {
    return (
      <div className="allowed-checkbox-option">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => props.selectOption(props.data)}
        />
        <label>{props.label}</label>
      </div>
    );
  };

  const MultiValue = (props) => {
    return (
      <components.MultiValue className="multi-value" {...props}>
        {props.children}
      </components.MultiValue>
    );
  };

  const ValueContainer = useCallback((props: ValueContainerProps) => {
    const length = props.children![0]?.length;
    const tmpChildren = [...props.children as Array<ReactNode>];

    if (length >= 3) {
      tmpChildren[0] = `${length} selected`;
    }

    return <components.ValueContainer {...props}>{tmpChildren}</components.ValueContainer>;
  }, []);

  const customComponents = {
    Option: CheckboxOption,
    ValueContainer,
    MultiValue,
    MultiValueRemove: () => null
  };

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      background: '#D5E2FC',
      color: 'black',
    }),
    valueContainer: (provided) => ({
      ...provided,
      flexWrap: 'nowrap'
    }),
    placeholder: (provided) => ({
      ...provided,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    }),
    menuPortal: base => ({ ...base, zIndex: 150 })
  };

  return (
    <div className="filter">
      <div className="filter-header">
        <div className="filter-title">{t('_advanced_search')}</div>

        <div className="filter-link-btn">
          <button className="add-btn" onClick={() => props.setOpenModal(true)}> <FontAwesomeIcon icon={faAdd} className="icon" /> {t('_create_price_rate')} </button>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="filter-body">
          <div className="filter-input-item">
            <Controller
              control={control}
              name="codfou"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                      onSubmit();
                    }}
                    options={props.providers}
                    placeholder={t('_provider')}
                  />
                );
              }}
            />
          </div>
          <div className="filter-input-item">
            <Controller
              control={control}
              name="type_form"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);
                      setValue('numcli', null);
                      resetField('numcli');

                    }}
                    options={[
                      {
                        value: '1',
                        label: String(t('_by_client')),
                      },
                      {
                        value: '2',
                        label: String(t('_by_country')),
                      },
                      {
                        value: '3',
                        label: String(t('_by_zone_commercial')),
                      },
                    ]}
                    isSearchable={!isMobile}
                  />
                );
              }}
            />
          </div>

          <div className={`filter-input-item ${watch()?.type_form?.value === '1' ? '' : 'hide'}`}>
            <Controller
              control={control}
              name="numcli"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);

                      if (val.length == 0) {
                        resetField('numcli');
                      }
                    }}
                    options={props.clients}
                    placeholder={t('_client')}
                    isSearchable={!isMobile}
                    isMulti
                    menuPlacement="auto"
                    isClearable={false}
                    menuPosition="fixed"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={customComponents}
                    styles={customStyles}
                  />
                );
              }}
            />
          </div>
          <div className={`filter-input-item ${watch()?.type_form?.value === '2' ? '' : 'hide'}`}>
            <Controller
              control={control}
              name="numcli"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);

                      if (val.length == 0) {
                        resetField('numcli');
                      }
                    }}
                    options={props.countries}
                    placeholder={t('_clients_country')}
                    isSearchable={!isMobile}
                    isMulti
                    menuPlacement="auto"
                    isClearable={false}
                    menuPosition="fixed"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={customComponents}
                    styles={customStyles}
                  />
                );
              }}
            />
          </div>
          <div className={`filter-input-item ${watch()?.type_form?.value === '3' ? '' : 'hide'}`}>
            <Controller
              control={control}
              name="numcli"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    className="select-dropdown"
                    onChange={(val) => {
                      onChange(val);

                      if (val.length == 0) {
                        resetField('numcli');
                      }
                    }}
                    options={props.commercialZones}
                    placeholder={t('_zone_commercial')}
                    isSearchable={!isMobile}
                    isMulti
                    menuPlacement="auto"
                    isClearable={false}
                    menuPosition="fixed"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={customComponents}
                    styles={customStyles}
                  />
                );
              }}
            />
          </div>
          <div className="filter-input-item">
            <TextInput {...register('group')} placeholder={t('_group')} onKeyDown={handleKeyDown} />
          </div>
          <DefaultButton className="price-filter-btn" text={t('_filter')} type="submit" />
          <DefaultButton className="price-filter-btn" text={t('_reset')} red onClick={resetFilter} type="button" />
        </div>
      </form>
    </div>
  );
};

export default PriceManagementFilter;
