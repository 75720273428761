import React, { useEffect } from 'react';

import AppView from './App.view';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import fr from 'javascript-time-ago/locale/fr.json';
import ru from 'javascript-time-ago/locale/ru.json';
import useRoutes from './utils/hooks/use-routes';
// import UnderMaintenancePage from 'applications/retailer/views/UnderMaintenancePage/UnderMaintenancePage';

declare global {
  interface Window {
    _mtm?: Array<{ [key: string]: unknown }>;
  }
}


const App: React.FC = () => {
  const applicationMode = useRoutes();

  TimeAgo.addLocale(en);
  TimeAgo.addLocale(ru);
  TimeAgo.addLocale(fr);

  // return <UnderMaintenancePage/>;

  useEffect(() => {
    const _mtm = (window._mtm = window._mtm || []);

    _mtm.push({ 'mtm.startTime': new Date().getTime(), 'event': 'mtm.Start' });

    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];

    g.async = true;
    g.src = 'https://cdn.matomo.cloud/e-reliable.matomo.cloud/container_WZdTyHHc.js';

    if (s.parentNode) {
      s.parentNode.insertBefore(g, s);
    }
  }, []);


  return <AppView routes={applicationMode} />;
};

App.displayName = 'App';

export default React.memo(App);
