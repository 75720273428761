import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { currencies } from 'utils/enums/marketplace';
import { getCurrentMarketplace, notify } from 'utils/marketplace';

import { IAddToCartParams } from 'models/car-parts.model';
import { addToCartAction } from 'store/actions/cart.actions';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import CartListButton from 'components/buttons/CartListButton/CartListButton';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import Disponibility from 'components/disponibility/Disponibility';
import { CATALOG_REL, Marketplaces } from 'utils/constants/applications';
import CURRENCY from 'utils/constants/currency';
// import {
//   MagnifierContainer,
//   MagnifierPreview,
//   MagnifierZoom,
// } from '@datobs/react-image-magnifiers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileClipboard } from '@fortawesome/free-regular-svg-icons';
import Modal from 'components/modal/Modal';

import './MecadepotCatalogItem.scss';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import ItemViewModal from '../ItemViewModal/ItemViewModal';

type Props = {
  data?;
};

const MecadepotCatalogItem: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const marketplace = getCurrentMarketplace();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [openModal, setOpenModal] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [quantityValue, setQuantityValue] = useState(Number(props.data?.available.uv || 1));
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const [searchParams] = useSearchParams();
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const [showPrice, setShowPrice] = useState(false);
  const isMecadepotChild = isRetailerMecadepotChild();

  const handleAddToCart = () => {
    if (!quantityValue) {
      return;
    }

    setCartLoading(true);
    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams;

    if (props.data?.catalog_type === 'tyre') {
      param = {
        tyre_id: props.data.tyre_id,
        price: props.data.price,
        quantity: quantityValue,
      };
    }
    else if (props.data?.catalog_type === 'battery') {
      param = {
        battery_id: props.data.battery_id,
        price: getPrice(),
        quantity: quantityValue,
      };
    }
    else if (props.data?.catalog_type === 'oil') {
      param = {
        oil_id: props.data.oil_id,
        price: getPrice(),
        quantity: quantityValue,
      };
    }
    else if (props.data?.catalog_type === 'consumable') {
      param = {
        consumable_id: props.data.consumable_id,
        price: getPrice(),
        quantity: quantityValue,
      };
    }
    else {
      param = {
        catalog_id: searchParams.get('catalog_id') ? String(searchParams.get('catalog_id')) : CATALOG_REL,
        cod_fou: props.data?.cod_fou,
        is_oe_part: false,
        price: props.data?.price?.price ? props.data?.price?.price : props.data?.price,
        quantity: quantityValue,
        supplier_id: props.data?.data_supplier.id,
        supplier_reference: props.data?.data_supplier_reference,
      };
    }

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param, ...{ external_cart_id: localStorage.getItem('external_cart_id') }
      };
    }

    params.push(param);
    dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));

    dispatch(addToCartAction(params))
      .catch(() => {
        dispatch(setQuantityToolbar(+quantityValue - +quantityToolbar));
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  function getPrice() {
    if (props.data.price?.price || props.data.price?.price == 0) {
      return props.data.price.price;
    }

    return props.data.price;
  }

  function calcLimit() {
    if (props.data.available && typeof props.data.available === 'object') {
      const newLimit: { uv?: string, warehouse_main } = { ...props.data.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return props.data.available;
  }

  function getAvailablityTitle() {
    if (props.data?.available?.warehouse_main) {
      const warehouses = { ...props.data.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach(key => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });


      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      return '';
    }

    if (props.data.available?.quantity) {
      if (props.data.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  useEffect(() => {
    const img = new Image();

    img.src = props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder-big.png';

    img.onload = () => {

      setImageDimensions({
        width: img.width > 200 ? 200 : img.width,
        height: img.height > 200 ? 200 : img.height,
      });
    };
  }, [props.data.image]);

  useEffect(() => {
    if (!calcLimit() && isMecadepotChild) {
      setDisableCartBtn(true);
    }
  }, [props.data]);

  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  function copyToClipboard() {
    navigator.clipboard.writeText(props.data.data_supplier_reference_original);
    notify(t('_reference_copied'), 'success');
  }

  return (
    <div className="mecadepot-catalog-item">
      <div className="mecadepot-catalog-item-favorite">
        {/* <FavoriteButton carPart={props.data} /> */}
      </div>
      <div className="mecadepot-catalog-header">
        <div className="product-image-container" onClick={() => setOpenModal(true)}>
          {/* <MagnifierContainer className="img-zoom-container" autoInPlace>
            <div className="img">
              <MagnifierPreview
                imageSrc={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder.png'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/images/car-part-placeholder-big.png';
                }}
              />
            </div>
            <MagnifierZoom className="img-zoom" style={imageDimensions} imageSrc={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder-big.png'} />
          </MagnifierContainer> */}
          <div className="img">
            <img
              src={props.data.image || props.data.data_supplier_logo || '/images/car-part-placeholder.png'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/images/car-part-placeholder-big.png';
              }}
              alt={props.data.subcategory?.name}
            />
          </div>
          <div className="image-product-name">
            {props.data?.data_supplier?.name}
          </div>
        </div>
        <div className="content">
          <div className="title">
            <span onClick={() => setOpenModal(true)}>{t('_reference_short')}: {props.data.data_supplier_reference_original} </span> <FontAwesomeIcon icon={faFileClipboard} onClick={copyToClipboard} />
          </div>
          {/* <div className="description-short" onClick={handleRefClick}> */}
          <div className="description-short" onClick={() => setOpenModal(true)}>
            {props.data.short_description}
          </div>
        </div>
      </div>
      <div className="mecadepot-catalog-footer">
        <div className="quantity">
          <Disponibility value={quantityValue} limit={props.data.available} title={getAvailablityTitle()} />
          {marketplace == Marketplaces.RETAILER || marketplace == Marketplaces.PUNCHOUT ?
            calcLimit() ?
              props.data.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} uv={props.data?.available?.uv} />
              : !calcLimit() && props.data.add_to_cart ? <QuantityInput uv={Number(props.data?.available?.uv)} setValue={setQuantityValue} value={quantityValue} /> : null
            :
            props.data.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} />
          }
        </div>
        <div className="cart">
          <div className="cart-price">
            <CarPartPrice currency={currencies.EUR} price={props.data?.price} priceVAT={props.data?.price?.price_with_vat} quantityValue={quantityValue} part />
          </div>
          <div className="cart-button">
            {props.data.add_to_cart && (
              <div className="button">
                <CartListButton
                  disableCartBtn={disableCartBtn}
                  onClick={handleAddToCart}
                  cartLoading={cartLoading}
                  value={quantityValue}
                  limit={calcLimit()}
                  addToCart={props.data.add_to_cart}
                  price={getPrice()}
                />
              </div>
            )}
          </div>
          {showPrice ? quantityValue > 1 && getPrice() ? <div className="oem-price_per_unit">P/U: {CURRENCY[user.currency]}{getPrice()}</div> : '' : ''}
        </div>
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={<ItemViewModal setOpenModal={setOpenModal} data={props.data} />}
      />
    </div>
  );
};

export default MecadepotCatalogItem;
