import React from 'react';
import './PriceCell.scss';
import CURRENCY from 'utils/constants/currency';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  value: number;
  unspecified?: boolean;
  workRow?: boolean;
};

const PriceCellView: React.FC<Props> = (props: Props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const isMecadepotChild = isRetailerMecadepotChild();

  return (
    <div className="price-cell-wrapper">
      {props.unspecified ? (
        '-'
      ) : (
        isMecadepotChild ?
          <span>
            {props.value} {`  ${CURRENCY[user!.currency]}`} HT
          </span> :
          <span>
            {CURRENCY[user!.currency]} HT {props.value}
          </span>
      )}
    </div>
  );
};

PriceCellView.displayName = 'PriceCellView';

export default PriceCellView;
