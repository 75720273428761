import Retailer from 'applications/retailer/Retailer';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { login } from 'store/reducers/useSlices';
import { useAppDispatch } from 'utils/hooks/redux';
import useHttp from 'utils/hooks/useHttp';
import '../DefaultCss.scss';

const Punchout: React.FC = () => {
  const http = useHttp();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.removeItem('sessionExpired');
      localStorage.setItem('external_cart_id', String(searchParams.get('cart_id')));
      localStorage.setItem('is_internal_client', String(searchParams.get('is_internal_client')));
      localStorage.setItem('default_warehouse', String(searchParams.get('default_warehouse')));
      sessionStorage.setItem('token', String(searchParams.get('token')));
      sessionStorage.setItem('logo_url', String(searchParams.get('logo_url')));
      sessionStorage.setItem('callback_order_url', String(searchParams.get('callback_order_url')));
      sessionStorage.setItem('callback_url', String(searchParams.get('callback_url')));
      searchParams.delete('token');
      searchParams.delete('logo_url');
      searchParams.delete('callback_order_url');
      searchParams.delete('callback_url');
      searchParams.delete('cart_id');
      searchParams.delete('is_internal_client');
      searchParams.delete('default_warehouse');
      setSearchParams(searchParams);
    }
  }, [searchParams.get('token')]);

  async function setUserInfo() {
    const user = await http.user.infoUser();

    localStorage.setItem('userData', JSON.stringify(user.data));
    dispatch(login(user.data));
  }

  useEffect(() => {
    if (pathname !== '/session-expired') {
      localStorage.removeItem('sessionExpired');
      setUserInfo();
    }
  }, []);

  return <Retailer />;
};

export default Punchout;
