import { createSlice } from '@reduxjs/toolkit';


type dlrnHernrCodfouByspecifyingReferenceSlice = {
  dlrnHernrCodfouByspecifyingReferenceActive: boolean;
  isLoading: boolean;
  typeEquivalence: string;
  loadingTableDlrnHernrCodfouByspecifyingReference: boolean;
};

const initialState: dlrnHernrCodfouByspecifyingReferenceSlice = {
  dlrnHernrCodfouByspecifyingReferenceActive: false,
  isLoading: false,
  typeEquivalence: '',
  loadingTableDlrnHernrCodfouByspecifyingReference: false,
};

const dlrnHernrCodfouByspecifyingReferenceSlice = createSlice({
  name: 'dlrnHernrCodfouByspecifyingReferenceSlice',
  initialState,
  reducers: {
    setDlrnHernrCodfouByspecifyingReferenceActive(state, { payload }) {
      state.dlrnHernrCodfouByspecifyingReferenceActive = payload;
    },
    setIsdlrnHernrCodfouByspecifyingReferenceLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setTypetEquivalence(state, { payload }) {
      state.typeEquivalence = payload;
    },
    setLoadingTableDlrnHernrCodfouByspecifyingReference(state, { payload }) {
      state.loadingTableDlrnHernrCodfouByspecifyingReference = payload;
    }

  },

});

export const { setDlrnHernrCodfouByspecifyingReferenceActive, setIsdlrnHernrCodfouByspecifyingReferenceLoading, setTypetEquivalence, setLoadingTableDlrnHernrCodfouByspecifyingReference } = dlrnHernrCodfouByspecifyingReferenceSlice.actions;

export default dlrnHernrCodfouByspecifyingReferenceSlice.reducer;
