import React from 'react';
import { faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import Disponibility from 'components/disponibility/Disponibility';
import Spinner from 'components/spinner/Spinner';
import { ITyre } from 'interfaces/tyre';
import CartListButton from 'components/buttons/CartListButton/CartListButton';
import { useTranslation } from 'react-i18next';
import { currencies } from 'utils/enums/marketplace';

import './ItemEshopTyre.scss';
import CheckboxCellTab from 'components/CheckboxCellTab/CheckboxCellTab';
import { getTranslationName } from '../../../utils/locale';
import CURRENCY from 'utils/constants/currency';
import { getBackendAssetsBasePath } from 'utils/marketplace';

type Props = {
  data: ITyre[];
  loading: boolean;
  openModalTyre?: (id: number) => void;
  deleteItem?: (id: number) => void;
  register;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopOffer: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnChangeTopSales: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ItemEshopTyreView: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();

  function getPrice(item) {
    if (item.price && typeof item.price === 'object') {
      if (item.price?.price) {
        return item.price.price;
      } else {
        return null;
      }
    }

    return item.price;
  }

  function getAvailablityTitle(item) {


    if (item.available && typeof item.available === 'object') {
      const newLimit: { uv?: string, warehouse_main } = { ...item.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return item.available;
  }

  return (
    <div className="item-container-shop-tyre">
      {props.loading ? (
        <Spinner />
      ) : props.data.length ? (
        props.data.map((item, index) => {
          return (
            <div className="item-shop" key={index}>
              <div className="header">
                <div className="checkboxCellTab">
                  <CheckboxCellTab handleOnChange={props.handleOnChange} id={item.tyre_id} register={props.register}></CheckboxCellTab>
                </div>
                <div className="img">
                  <img
                    src={item.image || item.data_supplier_logo || getBackendAssetsBasePath() + '/images/default/tyre.png'}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = '/images/car-part-placeholder.png';
                    }}
                  />
                </div>

                <div className="content">
                  <div className="title">{item.data_supplier.name}</div>
                  <div className="reference">
                    {getTranslationName(item.tags.season)} {item.data_supplier_reference}
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="description-short">
                  {item.tags.width}/{item.tags.height},
                  R {item.tags.diameter} , {item.tags.load_index} , {item.tags.speed_rating} ,&nbsp;
                  {getTranslationName(item.tags.season)}
                </div>
                <div className="description"><span>{item.description}</span></div>

                <div className="top-offer-checkbox-container">
                  <label htmlFor={`top_offer_${item.tyre_id}`}>{t('_top_offer')}</label>
                  <input
                    type="checkbox"
                    id={`top_offer_${item.tyre_id}`}
                    defaultChecked={item.top_offer}
                    value={item.tyre_id}
                    {...props.register('top_offer')}
                    onChange={props.handleOnChangeTopOffer}
                  />
                </div>
                {user?.hasTopSales ?
                  <div className="top-sales-checkbox-container">
                    <label htmlFor={`top_sales_${item.tyre_id}`}>{t('_top_sales')}</label>
                    <input
                      type="checkbox"
                      id={`top_sales_${item.tyre_id}`}
                      defaultChecked={item.top_sales}
                      value={item.tyre_id}
                      {...props.register('top_sales')}
                      onChange={props.handleOnChangeTopSales}
                    />
                  </div>
                  : null}
              </div>

              <div className="footer">
                <div className="quantity">

                  <Disponibility value={0} limit={getAvailablityTitle(item)} />
                  <div className="disponibility-porduct">
                    {' '}
                    {t('_up_to')} :{' '}
                    <span className="disponibility-count">
                      {getAvailablityTitle(item)} {t('_products')}
                    </span>
                  </div>

                </div>
                {props.openModalTyre && props.deleteItem ? (
                  <div className="cart">
                    <div className="cart-price">{getPrice(item) ? `${CURRENCY[user!.currency]}${getPrice(item)}` : '-'} </div>
                    <div className="cart-button">
                      <button className="button-edit" onClick={() => props.openModalTyre && props.openModalTyre(item.tyre_id)}>
                        <FontAwesomeIcon icon={faPen} className="icon-edit" />
                      </button>
                      <button className="button-delete" onClick={() => props.deleteItem && props.deleteItem(item.tyre_id)}>
                        <FontAwesomeIcon icon={faRemove} className="icon-delete" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="cart">
                    <div className="cart-price">
                      <CarPartPrice currency={currencies.EUR} price={item.price} part />
                    </div>
                    <div className="cart-button">
                      {/* <div className="button">
                        <FileButton />
                      </div> */}
                      <div className="button">
                        <CartListButton addToCart />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-data">{t('_no_data')}</div>
      )}
    </div>
  );
};

ItemEshopTyreView.displayName = 'IItemEshopTyreView';

export default ItemEshopTyreView;
