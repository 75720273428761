import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faList, faBoxOpen, faFileLines, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import CartButton from '../buttons/CartButton/CartButton';
import AccountDropdown from '../dropdowns/AccountDropdown/AccountDropdown';
import PhoneDropdown from '../dropdowns/PhoneDropdown/PhoneDropdown';
import MiniDropdown from '../dropdowns/MiniDropdown/MiniDropdown';
import { SidebarContext, ISidebarContext } from 'context/SidebarContext';

import { TabCategories } from 'views/Sidebar/Sidebar.model';
import Modal from 'components/modal/Modal';

import { useTranslation } from 'react-i18next';
import ModalCars from './ModalCars/ModalCars';
import i18n from 'i18n';
import { IUserPut } from 'utils/hooks/http/user.http';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import { getCountryFromLocale, getCountryFromLocalStorage, getLanguageFromLocale, getLanguageFromLocalStorage } from 'utils/locale';
import useHttp from 'utils/hooks/useHttp';
import { countries, DEFAULT_COUNTRY_IMAGE, languages } from 'utils/constants/locales';
import { useLocation, useNavigate } from 'react-router-dom';
import TechnicalHelp from 'components/dropdowns/TechnicalHelp/TechnicalHelp';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { Marketplaces } from 'utils/constants/applications';
import './Navbar.scss';

const Navbar: React.FC = () => {
  const { setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const location = useLocation();
  const { carInfo } = useSelector((state: RootState) => state.carSelected);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { user } = useSelector((state: RootState) => state.userData);

  const carInfoSelected = localStorage.getItem('carSelected') || carInfo ? JSON.parse(localStorage.getItem('carSelected') as string) : null;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const http = useHttp();
  const [link, setLink] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);
  const [selectedCountry, setSelectedCountry] = useState<IDropdownModel>(getCountryFromLocalStorage() ?? countries[0]);
  const navigate = useNavigate();

  const clickCategoryPieces = () => {
    if (carInfoSelected) {
      setSidebarTab(TabCategories.Pieces);
    } else {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }

    const country = getCountryFromLocale(i18n);

    if (country) {
      setSelectedCountry(country);
    }
  }, [i18n.language]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    const userData: IUserPut = {
      language: String(item.id),
    };

    try {
      http.user.putUser(userData);
    } catch (e) {
      notify('Error');
    }

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  const changeCountry = (item: IDropdownModel) => {
    const language = getLanguageFromLocale(i18n);

    if (!language) {
      return;
    }

    const locale = `${language.id}-${item.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  useEffect(() => {
    setTimeout(() => {
      http.cart.getCartQuantity().then((res) => {
        dispatch(setQuantityToolbar(res.quantity));
      });
    }, 700);
  }, []);

  const technicalDocumentationIsDisabled = () => {
    return user?.client_rmi_modules_type === 'none' && !user.client_settings.eina_subscription;
  };


  const home = () => {
    getCurrentMarketplace() == Marketplaces.PUNCHOUT ? window.location.href = String(sessionStorage.getItem('callback_url')) : navigate('/');

  };


  return (
    <div className="navbar">
      <div className="logos">
        <div className="garage-michel" onClick={() => home()}>
          <img
            src={
              sessionStorage.getItem('logo_url') !== 'null' && sessionStorage.getItem('logo_url')
                ? sessionStorage.getItem('logo_url')!
                : String(user?.client_logo)
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder.png';
            }}
          />
        </div>
        <div className="info-client-setings">
          <img src={require('../../assets/images/e-reliable.png')} width="87" height="10" alt="e-reliable" onClick={() => navigate('/')} />
          {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ?
            <React.Fragment>
              <div className="info-client">
                {user?.client_name}

              </div>
              <div className="info-client-erp">
                {user?.client_erp_id}
              </div>
            </React.Fragment>
            : null
          }
        </div>
      </div>
      <div className="tabs">
        <div className={`tab ${location.pathname == '/rapid-search' ? 'active' : ''}`} onClick={() => navigate('/rapid-search')}>
          <FontAwesomeIcon className="tab-icon" icon={faSearch} width="15" height="15" />
          <div className="text">{t('_rapid_search')}</div>
        </div>
        <div className={`tab ${location.pathname.includes('/catalogs') ? 'active' : ''}`} onClick={() => clickCategoryPieces()}>
          <FontAwesomeIcon className="tab-icon" icon={faList} width="15" height="15" />
          <div className="text">{t('_catalogs')}</div>
        </div>
        <div
          className={`${technicalDocumentationIsDisabled() ? 'disabled' : ''} tab ${location.pathname.includes('/technical-documentation') || location.pathname.includes('/error-codes') ? 'active' : ''} `}
        >
          <TechnicalHelp setOpenModal={setOpenModal} setLink={setLink} />
        </div>
        <div className={`tab ${location.pathname.includes('/orders') ? 'active' : ''}`} onClick={() => navigate('/orders')}>
          <FontAwesomeIcon className="tab-icon" icon={faBoxOpen} width="15" height="15" />
          <div className="text">{t('_my_orders')}</div>
        </div>
        <div className="tab disabled">
          <FontAwesomeIcon className="tab-icon" icon={faFileLines} width="15" height="15" />
          <div className="text ">{t('_my_quotes')}</div>
        </div>
        <div className={`tab ${location.pathname.includes('/news') ? 'active' : ''}`} onClick={() => navigate('/news')}>
          <FontAwesomeIcon className="tab-icon" icon={faNewspaper} width="15" height="15" />
          <div className="text ">{t('_news')}</div>
        </div>
      </div>
      <div className="user-settings">
        <PhoneDropdown />
        <AccountDropdown />
        <CartButton quantityToolbar={quantityToolbar} />
        <div className="mini-dropdowns">
          <MiniDropdown onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
          <MiniDropdown onClick={changeCountry} image={selectedCountry ? selectedCountry.image : DEFAULT_COUNTRY_IMAGE} data={countries} />
        </div>
      </div>

      <Modal openModal={openModal} setOpenModal={setOpenModal} childrenComponent={<ModalCars setOpenModal={setOpenModal} link={link} />} />
    </div >
  );
};

Navbar.displayName = 'Navbar';

export default Navbar;
