import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Pagination from 'components/pagination/Pagination';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IBodyResponseOil } from 'interfaces/oil';
import { IOilCatalogFilter } from 'utils/hooks/http/oils-catalog.http';

import OilNavbar from 'components/navbar/oil-navbar/OilNavbar';
import ProductsViewMode from '../../../../components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from '../../../../components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import {
  CATALOG_OIL
} from '../../../../components/ProductsViewModeChanger/products-view-mode-changer-utils';
import List from 'components/List/List';
import CatalogOilFilter from './CatalogOilFilter/CatalogOilFilter';
import axios from 'axios';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import './CatalogOils.scss';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import { filterObject } from '../../../../utils/utils';
import TextInput from '../../../../components/inputs/TextInput/TextInput';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import { ICustomValidation } from 'utils/hooks/http/search-by-reference.http';

const CatalogOils: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseOil>();
  const [filterData, setFilterData] = useState<IOilCatalogFilter>();
  const [openFilter, setOpenFilter] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [oilCategories, setOilCategories] = useState<ICategory[]>([]);
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : oilCategories[0]?.id });
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const [clearable, setClearable] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  function getParams() {
    return {
      brand_id: searchParams.getAll('brand_id'),
      viscosity: searchParams.getAll('viscosity'),
      quantity: searchParams.getAll('quantity'),
      search: searchParams.get('search'),
      family: searchParams.getAll('family'),
      category_ids: searchParams.getAll('category_id')
    };
  }

  function getOilCategories(cancelToken) {
    setLoadingCategories(true);

    const filteredParams = filterObject(getParams());

    setLoading(true);
    http.catalogs.getCarOilAllCategories(validateParams(filteredParams), cancelToken).then((res) => {

      setOilCategories(res);
      const firstValidCategory = res.find((category) => category.number_of_products !== 0);

      if (searchParams.get('category_id') || firstValidCategory) {
        const param = filterObject({
          ...filteredParams,
          category_ids: searchParams.get('category_id') ? searchParams.getAll('category_id') : [String(firstValidCategory?.id)]
        });

        getOilData(validateParams(param));
      } else {

        setRefData(undefined);
        setLoading(false);
      }
    }).catch(() => {
      setRefData(undefined);
      setLoading(false);
    }).finally(() => setLoadingCategories(false));

    return cancelToken;

  }


  function getOilData(data) {
    setLoading(true);

    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      page,
    };

    const validParams = validateParams(params);

    http.oilCatalogHttp
      .getOils(validParams)
      .then((res) => {
        setRefData(res.data);
        setTimeout(() => {
          if (params.page) {
            const firstChild = document.querySelector('.mecadepot-catalog-item');

            if (firstChild) {
              firstChild.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
          }
        }, 100);
      })
      .finally(() => {
        setLoading(false);


      })
      .catch(() => {

        setLoading(false);
      });
  }

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    // Verifică dacă `category_id` este singurul parametru
    if ((searchParams.has('category_id') && Array.from(searchParams.keys()).length === 1) || searchParams.has('page')) {

      const params = getParams();

      getOilData(validateParams(filterObject(params)));
    } else {

      getOilCategories(cancelToken.token);
    }


    return () => {
      cancelToken.cancel('Anulare datorită schimbării de dependență.');
    };
  }, [i18n.language, search]);


  useEffect(() => {
    searchParams.delete('category_id');
    searchParams.delete('page');

    if (searchInput) {
      searchParams.set('search', searchInput);
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);

  }, [searchInput]);

  useEffect(() => {

    const firstValidCategory = oilCategories.find((category) => category.number_of_products !== 0);

    setActiveCategory({ id: Number(searchParams.get('category_id')) || Number(firstValidCategory?.id) });
  }, [oilCategories, searchParams.get('category_id')]);


  function getOilFilter(data) {

    const validFilterParams = validateParams(data);

    http.oilCatalogHttp.getOilFilters(validFilterParams).then((res) => {
      setFilterData(res.data);
    });
  }

  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };


  useEffect(() => {
    getOilFilter({});
  }, [i18n.language]);

  const onCategoryClick = (category) => {
    searchParams.set('category_id', category.id);
    searchParams.delete('page');
    setSearchParams(searchParams);
    setPage(1);
  };


  function filterOptionsElement() {
    return (
      <div className="catalog-parts-filter">
        <button onClick={() => setOpenFilter(true)} className={`catalog-parts-button ${openFilter ? 'active' : ''}`}>
          {t('_filter_results_btn')}
        </button>
      </div>
    );
  }

  const customValidations: ICustomValidation[] = [
    {
      regex: new RegExp(/^.{3,}$/),
      message: t('_ref_search_min_length'),
    }
  ];

  const onClickReset = () => {
    ref.current!.value = '';
    setSearchInput('');
    setClearable(false);

  };

  const refInputChange = (event) => {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim();

    setSearchInput(inputData);

    if (inputData.length) {

      setClearable(true);
    } else {
      setClearable(false);
    }
  };

  return (
    <div className="catalog-oils-wrapper">
      <OilNavbar option={filterOptionsElement()} title="_oil_catalog" showSortBy={isRetailerMecadepotChild()} />
      <div className="separator"></div>

      <div className="filterOptions">
        <span className="search-parts-title">{t('_search')}</span>
        <TextInput
          name="search"
          placeholder="Ex: 1003001F"
          onChange={(e) => refInputChange(e)}
          withIcon
          customValidations={customValidations}
          clearable={clearable}
          onClickReset={onClickReset}
          ref={ref}
        />
        {(refData?.data?.length && refData?.data?.length > 0 && !isRetailerMecadepotChild()) ? <SortBy /> : null}
        <div className="results">
          {refData?.total_count} {t('_results')}
        </div>
        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

      </div>

      <div className="retailer-catalog-oil-container">
        <div className="catalog-oil-sidebar">
          {oilCategories && oilCategories.length ? (
            <List
              content={oilCategories?.map((item) => {
                return { ...item, id: item.id, name: item.name };
              })}
              images={oilCategories?.map((item) => item.image)}
              itemClassName="parts-list"
              itemId="id"
              onItemClick={onCategoryClick}
              activeCategory={activeCategory}
            />
          ) : null}
        </div>

        <div className="wrapperItems">
          {loadingCategories || loading ? (
            <Spinner class="car-parts-spinner" />
          ) : refData?.data?.length ? (
            <div>
              <ProductsViewModeChanger catalog={CATALOG_OIL} viewMode={viewMode} data={refData?.data} />
            </div>
          ) : (
            <div className="no-parts-data">
              <div className="notFoundSvg">
                <PartsNotFoundImage />
              </div>
              <h1>{t('_oils_not_found')}</h1>
            </div>
          )}
          {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && !loading ? (
            <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
          ) : (
            null
          )}
        </div>
      </div>
      <CatalogOilFilter getData={getOilData} filterData={filterData} openFilter={openFilter} setOpenFilter={setOpenFilter} />
    </div>
  );
};

export default CatalogOils;
