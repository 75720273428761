import React, { useEffect, useState } from 'react';
import GeneralSearch from 'components/general-search/GeneralSearch';
import { IUser } from 'models/IUser';
import { useTranslation } from 'react-i18next';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import GlobalSearchInput from '../../components/inputs/GlobalSearchInput/GlobalSearchInput';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { useNavigate } from 'react-router-dom';
import { retailerCatalogItems } from 'applications/automotor/components/espace/client/catalog/Catalog.map';
import { ICatalogModel } from 'applications/automotor/components/espace/client/catalog/catalog.model';

import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import ProductCategoryItemNew from 'components/Item/ProductCategoryItemNew/ProductCategoryItemNew';
import './HomeMecadepot.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Marketplaces } from 'utils/constants/applications';
import { getCurrentMarketplace } from 'utils/marketplace';
import PartsTopOfferSlider from 'applications/retailer/components/PartsTopOfferSlider/PartsTopOfferSlider';
import SimpleAd from '../../components/AdsModule/SimpleAd/SimpleAd';

type Props = {
  handleLogout: () => void;
  user: IUser | null;
};

const HomeMecadepotView: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();
  const [loadingAd, setLoadingAd] = useState<boolean>(false);
  const [adItems, setAdItems] = useState<IPromotionDetails[]>([]);
  const [loadingBrand, setLoadingBrand] = useState<boolean>(false);
  const http = useHttp();
  const navigate = useNavigate();
  const [brandsSlideShow, setBrandsSlideShow] = useState<string[]>([]);
  const { user } = useSelector((state: RootState) => state.userData);
  const [userData, setUserData] = useState<IUser>(user as IUser);
  const [informativeMessage, setInformativeMessage] = useState<string>('');

  useEffect(() => {
    getAds();
    getBrandImage();
  }, [i18n.language]);

  function getAds() {
    setLoadingAd(true);
    http.promotions.activePromotions().then((res) => {
      setAdItems(res.data);

    }).finally(() => {
      setLoadingAd(false);

    }).catch(() => {
      setLoadingAd(false);
      setAdItems([]);
    });
  }

  function getBrandImage() {
    setLoadingBrand(true);
    http.retailerHttp.getBrandsSlideShow().then((res) => {
      setBrandsSlideShow(res.data);
    }).finally(() => { setLoadingBrand(false); })
      .catch(() => {
        setLoadingBrand(false);
      });
  }

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  useEffect(() => {
    http.retailerHttp.getInformativeMessage().then((res) => {
      setInformativeMessage(res.data.message);
    });
  }, []);

  const filteredCatalogItems = getCurrentMarketplace() === Marketplaces.RETAILER || getCurrentMarketplace() === Marketplaces.PUNCHOUT ? retailerCatalogItems(userData).filter((item) => item.disabled === false) : [];

  return (
    <div className="mecadepot-content">
      <div className="mecadepot-home">
        {!isMobile && !isTablet &&
          <div className="general-search">
            <div className="slide-show-brand">
              <div className="brand-slider-home">
                <SwiperSlider isLoading={loadingBrand} slidesPerView={1} loop autoplay>
                  {brandsSlideShow.map((item: string, index: number) => {
                    return (
                      <SwiperSlide key={index} className="swiper-slide-home">
                        <img className="image" src={item} alt="image" />
                      </SwiperSlide>
                    );
                  })}
                </SwiperSlider>
              </div>
            </div>
            <GeneralSearch />
          </div>
        }
        <div className="home">
          <div className="marquee">
            {
              informativeMessage && informativeMessage.length ? <span>{informativeMessage}</span> : null
            }
          </div>
          <div className="retailer-catalog-search">
            <div className="header-search">
              <div className="header-search-input">
                <GlobalSearchInput
                  // value=""
                  placeholder={t('_free_search')}
                  size="l"
                  withIcon
                />
              </div>
            </div>
            {!isMobile ? (
              filteredCatalogItems.length > 0 &&
              <div className="retailer-catalog-items">
                {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
                  return (
                    <ProductCategoryItemNew text={t(item.text)} image={item.image} mainImage={item.mainImage} onClick={() => navigate(item.link)} key={index} />);
                })}
              </div>
            ) : (
              filteredCatalogItems.length > 0 &&
              <div className="retailer-catalog-items">
                <SwiperSlider slidesPerView="auto" spaceBetween={0} slideToIndex="auto" loop>
                  {filteredCatalogItems.map((item: ICatalogModel, index: number) => {
                    return (
                      <SwiperSlide key={index} className="swiper-slide swiper-navbar-item">
                        <ProductCategoryItemNew text={t(item.text)} image={item.image} onClick={() => navigate(item.link)} />
                      </SwiperSlide>
                    );
                  })}
                </SwiperSlider>
              </div>
            )}

            {isMobile || isTablet ?
              <React.Fragment>
                <div className="slide-show-brand">
                  <div className="brand-slider-home">
                    <SwiperSlider isLoading={loadingBrand} slidesPerView="auto" autoplay>
                      {brandsSlideShow.map((item: string, index: number) => {
                        return (
                          <SwiperSlide key={index} className="swiper-slide ">
                            <img className="image" src={item} alt="image" />
                          </SwiperSlide>
                        );
                      })}
                    </SwiperSlider>
                  </div>
                </div>
                <div className="general-search">
                  <GeneralSearch />
                </div>
              </React.Fragment>
              : null
            }
          </div>

          <div className="retailer-home-content">
            <PartsTopOfferSlider />
            {adItems.length ?
              <div className="ads-module-slider  simple-ad">
                {
                  loadingAd ? <Spinner class="small" /> :
                    <SimpleAd
                      items={adItems}
                      slidesPerView={1}
                      navigation={!(isMobile || isTablet) && adItems.length != 1}
                      pagination={isMobile || isTablet}
                      autoplay={adItems.length != 1}
                      loop={adItems.length != 1}
                      delay={5000}
                    />
                }
              </div>
              : null
            }

          </div>

        </div>
      </div>
    </div>
  );
};

HomeMecadepotView.displayName = 'HomeMecadepotView';

export default HomeMecadepotView;
