import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentMarketplace } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import './CirrusDisponibility.scss';


type Props = {
  value: number;
  data?
  loading?: boolean;
  title?: string | boolean
  price?
  setDisableCartBtn?: (val: boolean) => void
  setIsInternalclient?: (val: boolean) => void
};
const CirrusDisponibility: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [stockColor, setStockColor] = useState('');
  const currentMarketplace = getCurrentMarketplace();


  const userInfo = JSON.parse(localStorage.getItem('userData')!);

  function getPrice() {
    if (props.price?.price) {
      return props.price.price;
    }

    return props.price;
  }

  function calcLimit() {
    if (props.data.available?.quantity) {
      return props.data.available.quantity;
    }

    if (props.data.available && typeof props.data.available === 'object') {
      const newLimit: { [key: string]: { quantity } } = { ...props.data.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => +acc! + Number(item?.quantity), 0);
    }

    return props.data.available;
  }

  function availabilityTitle() {
    if (props.data.available && typeof props.data.available.warehouse_main === 'object') {

      // let deliveryTime = 0;

      // Object.keys(props.data.available).forEach(key => {
      //   if (key !== 'uv' && props.data.available.hasOwnProperty!(key)) {

      //     if (props.data.available[key].warehouse_delivery_time) {
      //       deliveryTime = Math.max(props.data.available[key].warehouse_delivery_time);
      //     }
      //   }
      // });

      if (props.data.available?.warehouse_main.quantity > 0) {
        return t('_disponible');
      }

      if (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity > 0) {
        return ' ';
      }

      if (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity === 0) {
        return '';
      }

      if (props.data.available?.warehouse_main.quantity === 0) {
        return '';
      }
    }
  }


  useEffect(() => {
    if (props.data.available && typeof props.data.available.warehouse_main === 'object') {
      if (props.data.available?.warehouse_main.quantity > 0) {
        setStockColor('green');
      }

      if (props.data.available?.warehouse_main.quantity === 0) {
        setStockColor('');
        props.setDisableCartBtn!(true);
      }

      if (props.data.available?.warehouse_main.quantity < props.value) {
        props.setDisableCartBtn!(true);
      } else {
        props.setDisableCartBtn!(false);
      }

      if (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity > 0) {
        setStockColor('orange');

        if (localStorage.getItem('is_internal_client') == '1') {
          props.setDisableCartBtn!(false);

          if (props.setIsInternalclient) {
            props.setIsInternalclient(true);
          }

        }
      }

      if (props.data.available?.warehouse_main.quantity === 0 && props.data.available?.warehouse_all.quantity === 0) {
        setStockColor('');
      }
    }
  }, [props.data.available, props.value]);

  return (
    <div className={`quantity-disponibility ${stockColor}`}>
      <div className="quantity-availability-stok-title">
        {availabilityTitle() ? availabilityTitle() : (
          <React.Fragment>
            {
              currentMarketplace == process.env.REACT_APP_MARKETPLACE_RETAILER || currentMarketplace == process.env.REACT_APP_MARKETPLACE_PUNCHOUT
                ?
                null
                :
                <span className="disponibility">
                  <span>{t('_dispo')}:</span>
                </span>
            }
            <div className={`disponible-title ${!calcLimit() && Number(calcLimit()) <= props?.value ? 'unavailable' : ''}`}>
              {
                currentMarketplace == process.env.REACT_APP_MARKETPLACE_RETAILER || currentMarketplace == process.env.REACT_APP_MARKETPLACE_PUNCHOUT ?
                  (calcLimit() && Number(calcLimit()) >= props?.value) && getPrice() ? (
                    <span className="stock-availability" >{t('_disponible')}</span>
                  ) :
                    (<div className="contact_number">
                      <span className="stock-unavailability title">{t('_non_disponible')}</span>
                      {
                        userInfo?.phone ?
                          <span className="stock-unavailability">
                            {t('_contact_number')}
                            <div>
                              <a href={`tel:${userInfo?.distributor_info?.main_address?.tel}`}> <FontAwesomeIcon icon={faPhone} />{userInfo?.distributor_info?.main_address?.tel}</a>
                            </div>
                          </span> : ''
                      }

                    </div>
                    ) : null
              }
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="quantity-availability-stok">
        <span className={`quantity-stock-availability ${stockColor}`}></span>
      </div>

    </div >
  );
};

export default CirrusDisponibility;
