import React, { useState } from 'react';
import BarCode from './BarCode/BarCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { currencies } from 'utils/enums/marketplace';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addToCartAction } from 'store/actions/cart.actions';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { RootState } from 'store/store';
import { IAddToCartParams } from 'models/car-parts.model';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import Disponibility from 'components/disponibility/Disponibility';
import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import IconButton from 'components/buttons/IconButton/IconButton';
import Spinner from 'components/spinner/Spinner';
import './TyresOptions.scss';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { Marketplaces } from 'utils/constants/applications';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import useHttp from 'utils/hooks/useHttp';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';

type Props = {
  tyre?;
};

const TyreOptions: React.FC<Props> = (props) => {
  const tyre = props.tyre;
  const [quantityValue, setQuantityValue] = useState<number>(tyre?.available?.uv || 1);
  const [addToCartButtonLoading, setAddToCartButtonLoading] = useState(false);
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const currentMarketplace = getCurrentMarketplace();
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const http = useHttp();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const isMecadepotChild = isRetailerMecadepotChild();
  const [isInternalclient, setIsInternalclient] = useState(false);

  const handleAddToCart = () => {
    if (!quantityValue) {
      return;
    }

    setAddToCartButtonLoading(true);

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      tyre_id: tyre.tyre_id,
      quantity: quantityValue,
      price: tyre.price,
    };

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    params.push(param);

    dispatch(setQuantityToolbar(Number(quantityValue) + Number(quantityToolbar)));
    dispatch(addToCartAction(params))
      .finally(() => {
        setAddToCartButtonLoading(false);
      })
      .catch(() => {
        dispatch(setQuantityToolbar(Number(quantityValue) - Number(quantityToolbar)));
      });
  };

  function calcLimit() {
    if (typeof tyre.available === 'object' && 'quantity' in tyre.available) {
      return tyre.available?.quantity;
    }

    if (tyre.available && typeof tyre.available === 'object') {
      const newLimit: { uv?: string; warehouse_main } = { ...tyre.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return tyre.available;
  }

  function getPrice() {

    if (tyre.price && typeof tyre.price === 'object') {
      if (tyre.price?.price) {
        return tyre.price.price;
      } else {
        return null;
      }
    }

    return tyre.price;
  }

  function getAvailablityTitle() {
    if (tyre?.available?.warehouse_main) {
      const warehouses = { ...tyre.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach((key) => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (!getPrice()) {
        return '';
      }

      return '';
    }

    if (tyre.available?.quantity) {
      if (tyre.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  function disableCart() {
    if (currentMarketplace == Marketplaces.RETAILER || currentMarketplace == Marketplaces.PUNCHOUT) {
      if (!calcLimit() && isMecadepotChild) {
        return true;
      }

      if (Number(calcLimit()) < quantityValue && !tyre?.add_to_cart) {
        return true;
      }

      if (!calcLimit() && isMecadepotChild) {
        return true;
      }

      if (addToCartButtonLoading || !quantityValue) {
        return true;
      }

      // if (Number(calcLimit()) === 0 && !getPrice()) {
      //   return true;
      // }

      if (!(calcLimit() && Number(calcLimit()) < quantityValue) && tyre?.add_to_cart) {
        return false;
      }
    }
  }

  function cirrusContactUs() {
    setContactLoading(true);
    const params = {
      source: tyre?.source,
      reference: tyre?.data_supplier_reference_original,
      supplier_id: tyre?.data_supplier?.id,
      product_type: 'tyre'
    };

    http.cirrus
      .contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  function contactUsBtn() {
    if (tyre.available && typeof tyre.available.warehouse_main === 'object') {
      if (
        (tyre.available?.warehouse_main.quantity === 0 && tyre.available?.warehouse_all.quantity > 0) ||
        (tyre.available?.warehouse_main.quantity === 0 && tyre.available?.warehouse_all.quantity === 0) ||
        tyre.available?.warehouse_main.quantity === 0
      ) {
        return (
          <div className="cirrus-contact-us" onClick={cirrusContactUs}>
            {contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}
          </div>
        );
      }

      return '';
    }

    return (
      <div className="cirrus-contact-us" onClick={cirrusContactUs}>
        {contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}
      </div>
    );
  }


  return (
    <div className="tyre-options-wrapper">
      <div className="top">
        <BarCode item={tyre} />
      </div>


      <div className="availability-add-to-cart-wrapper">
        <div className="bottom">
          <div className="availability">
            {getCurrentMarketplace() == Marketplaces.RETAILER || getCurrentMarketplace() == Marketplaces.PUNCHOUT ? (
              calcLimit() ? (
                tyre.add_to_cart && (
                  <QuantityInput uv={Number(tyre?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
                )
              ) : !calcLimit() && tyre.add_to_cart ? (
                <QuantityInput uv={Number(tyre?.available?.uv)} setValue={setQuantityValue} value={quantityValue} />
              ) : null
            ) : (
              tyre.add_to_cart && <QuantityInput setValue={setQuantityValue} value={quantityValue} />
            )}
            {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
              <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} loading={false} value={quantityValue} data={tyre} setIsInternalclient={setIsInternalclient} />
            ) : (
              <Disponibility
                loading={false}
                value={quantityValue}
                limit={tyre?.available}
                title={getAvailablityTitle()}
                price={tyre.price}
              />
            )}
            <CarPartPrice loading={false} currency={currencies.EUR} price={tyre?.price} priceVAT={tyre?.price?.price_with_vat} quantityValue={quantityValue} />
          </div>
          {isMobile ? (
            <div className="mobile-buttons">
              <div className="add-to-quote-button">
                <IconButton
                  text={t('_add_to_quote')}
                  className="iconButton"
                  icon={<FontAwesomeIcon icon={faFileLines} className="add-to-quote" />}
                ></IconButton>
              </div>
              {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient ? (
                contactUsBtn()
              ) : (
                tyre?.add_to_cart && (
                  <div className="add-to-cart-button">
                    <IconButton
                      text={t('_add_to_cart')}
                      disabled={disableCart()}
                      className="iconButton"
                      onClick={handleAddToCart}
                      icon={
                        addToCartButtonLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />
                      }
                    ></IconButton>
                  </div>
                ))}
            </div>
          ) : (
            user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient ? (
              contactUsBtn()
            ) : (
              tyre?.add_to_cart && (
                <div className="button">
                  <IconButton
                    text={t('_add_to_cart')}
                    disabled={disableCart()}
                    className="iconButton"
                    onClick={handleAddToCart}
                    icon={
                      addToCartButtonLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />
                    }
                  ></IconButton>
                </div>
              ))
          )}
        </div>
      </div>

    </div>
  );
};

TyreOptions.displayName = 'TyreOptions';

export default TyreOptions;
