import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { currencies } from 'utils/enums/marketplace';
import CartListButton from '../buttons/CartListButton/CartListButton';
import Disponibility from '../disponibility/Disponibility';
import QuantityInput from '../inputs/QuantityInput/QuantityInput';
import { faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAddToCartParams } from 'models/car-parts.model';
import { Marketplaces } from 'utils/constants/applications';
import { addToCartAction } from 'store/actions/cart.actions';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import FUEL_ECONOMY from '../../assets/images/fuel-economy.png';
import WET_PERFORMANCE from '../../assets/images/wet-performance.png';
import DECIBEL_CLASS from '../../assets/images/decibel-class.png';
import { GRID_VIEW } from '../ProductsViewMode/ProductsViewMode.constants';
import { getCurrentMarketplace, notify } from 'utils/marketplace';
import { getTranslationName } from '../../utils/locale';
import CURRENCY from 'utils/constants/currency';
import CirrusDisponibility from 'components/CirrusDisponibility/CirrusDisponibility';
import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import './TyreCard.scss';
import {
  MagnifierContainer,
  MagnifierPreview,
  MagnifierZoom,
} from '@datobs/react-image-magnifiers';
import Modal from 'components/modal/Modal';
import ItemViewModal from 'pages/CatalogVehicle/Catalogs/ItemViewModal/ItemViewModal';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import { checkIfImageExists } from 'utils/utils';
import Placeholder from 'assets/images/car-part-placeholder.png';

type Props = {
  tyreData
  openModalTyre?: (id: number) => void;
  deleteItem?: (id: number) => void;
};

const TyreCard: React.FC<Props> = (props) => {
  const user = JSON.parse(localStorage.getItem('userData')!);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [cartLoading, setCartLoading] = useState(false);
  const [quantityValue, setQuantityValue] = useState(1);
  const [image, setImage] = useState('');
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const navigate = useNavigate();
  const marketplace = getCurrentMarketplace();
  const [contactLoading, setContactLoading] = useState(false);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const http = useHttp();
  const [searchParams] = useSearchParams();
  const { vehicle_id } = useParams<'vehicle_id'>();
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const [showPrice, setShowPrice] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isMecadepotChild = isRetailerMecadepotChild();
  const [isInternalclient, setIsInternalclient] = useState(false);

  const handleAddToCart = () => {
    if (!quantityValue) {
      return;
    }

    setCartLoading(true);

    const params: IAddToCartParams[] = [];

    let param: IAddToCartParams = {
      tyre_id: props.tyreData.tyre_id,
      price: props.tyreData.price,
      quantity: quantityValue,
    };

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param, ...{ external_cart_id: localStorage.getItem('external_cart_id') }
      };
    }

    params.push(param);
    dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));

    dispatch(addToCartAction(params))
      .catch(() => {
        dispatch(setQuantityToolbar(+quantityValue - +quantityToolbar));
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  function handleRefClick() {

    let params;

    params = [
      ['tyre_id', props.tyreData.tyre_id.toString()],
      ['data_supplier_name', props.tyreData.data_supplier.name],
      ['data_supplier_reference', props.tyreData.data_supplier_reference],
    ];

    if (searchParams.get('vehicle_identifier')) {
      params = [...params, ['vehicle_identifier', searchParams.get('vehicle_identifier')]];
    }

    if (searchParams.get('source')) {
      params = [...params, ['source', searchParams.get('source')]];
    }

    if (vehicle_id) {

      navigate({ pathname: generatePath(`/reliable/rmi/${vehicle_id}/catalogs/tyres/view/:id`, { id: props.tyreData.tyre_id }), search: `?${createSearchParams(params)}` });
    } else {
      navigate({
        pathname: generatePath('/tyres/view/:id', {
          id: props.tyreData.tyre_id.toString(),
        }),
        search: `?${createSearchParams(params)}`,
      });
    }
  }

  function calcLimit() {
    if (props.tyreData.available && typeof props.tyreData.available === 'object') {
      const newLimit: { uv?: string, warehouse_main } = { ...props.tyreData.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return props.tyreData.available;
  }

  function getAvailablityTitle() {
    if (props.tyreData?.available?.warehouse_main) {
      const warehouses = { ...props.tyreData.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach(key => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });


      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      return '';
    }

    if (props.tyreData.available?.quantity) {
      if (props.tyreData.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  function cirrusContactUs() {
    setContactLoading(true);

    const params = {
      source: props.tyreData?.source,
      reference: props.tyreData?.data_supplier_reference_original,
      supplier_id: props.tyreData?.data_supplier?.id,
      product_type: 'tyre'
    };

    http.cirrus.contactUs(params)
      .then(() => notify(t('_distributor_contact_soon'), 'success'))
      .catch((err) => notify(err.message))
      .finally(() => setContactLoading(false));
  }

  function getPrice() {
    if (props.tyreData.price?.price) {
      return props.tyreData.price.price;
    }

    return props.tyreData.price;
  }

  useEffect(() => {
    const img = new Image();

    img.src = props.tyreData.image || '/images/car-part-placeholder-big.png';

    img.onload = () => {

      setImageDimensions({
        width: img.width > 200 ? 200 : img.width,
        height: img.height > 200 ? 200 : img.height,
      });
    };
  }, [props.tyreData.image]);

  useEffect(() => {
    checkIfImageExists(props.tyreData.image, (exists) => (exists ? setImage(props.tyreData.image) : setImage(Placeholder)));
  }, []);

  function contactUsBtn() {
    if (props.tyreData.available && typeof props.tyreData.available.warehouse_main === 'object') {
      if ((props.tyreData.available?.warehouse_main.quantity === 0 && props.tyreData.available?.warehouse_all.quantity > 0) || (props.tyreData.available?.warehouse_main.quantity === 0 && props.tyreData.available?.warehouse_all.quantity === 0) || props.tyreData.available?.warehouse_main.quantity === 0) {
        return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
      }

      return '';
    }

    return <div className="cirrus-contact-us" onClick={cirrusContactUs}>{contactLoading ? <Spinner class="extra-small"></Spinner> : t('_contact_us')}</div>;
  }

  useEffect(() => {
    if (!calcLimit() && isMecadepotChild) {
      setDisableCartBtn(true);
    }
  }, [props.tyreData]);

  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  return (
    <div className={`${GRID_VIEW}-item-container-shop-tyre`}>
      <div className="item-shop">
        <div className="tyre-header" >
          {/*           <MagnifierContainer className="img-zoom-container">
            <div className="img">
              <MagnifierPreview
                imageSrc={props.tyreData.image || props.tyreData.data_supplier_logo || '/images/car-part-placeholder.png'}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/car-part-placeholder-big.png';
                }}
              />
            </div>
            <MagnifierZoom className="img-zoom" style={imageDimensions} imageSrc={props.tyreData.image || props.tyreData.data_supplier_logo || '/images/car-part-placeholder-big.png'} />
          </MagnifierContainer> */}
          <MagnifierContainer className="img-zoom-container" autoInPlace>
            <div className="img">
              <MagnifierPreview
                imageSrc={image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/images/car-part-placeholder-big.png';
                }}
              />
            </div>
            <MagnifierZoom className="img-zoom" style={{ height: '200px', width: '200px' }} imageSrc={image} />
          </MagnifierContainer>
          {/* <div className="img">
            <img src={props.tyreData.image || '/images/car-part-placeholder.png'} alt={props.tyreData.subcategory?.name} onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping

              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            />
          </div> */}
          <div className="content">
            {isRetailerMecadepotChild() ?
              <div className="title" onClick={() => setOpenModal(true)}>{props.tyreData.data_supplier.name}</div>
              :
              <div className="title" onClick={() => handleRefClick()}>{props.tyreData.data_supplier.name}</div>
            }
            <div className="reference">{props.tyreData.data_supplier_reference}</div>
            <div className="description-short">
              {props.tyreData.tags.width}/{props.tyreData.tags.height} R{props.tyreData.tags.diameter}, {props.tyreData.tags.load_index}
              {props.tyreData.tags.speed_rating},&nbsp;
              {getTranslationName(props.tyreData.tags.season)}
            </div>
            <div className="description-short">{props.tyreData.description}</div>
          </div>
        </div>
        <div className="body">
          <div className="classes-container">
            <img src={FUEL_ECONOMY} alt="FUEL ECONOMY" className="class-img" />
            <span className="class-index-fuel">A</span>
          </div>
          <div className="classes-container">
            <img src={WET_PERFORMANCE} alt="FUEL ECONOMY" className="class-img" />
            <span className="class-index-wet">B</span>
          </div>
          <div className="classes-container">
            <img src={DECIBEL_CLASS} alt="FUEL ECONOMY" className="class-img" />
            <span className="class-index-decibel">68</span>
          </div>
        </div>
        <div className="tyre-footer">

          <div className="quantity">
            {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) ? (
              <CirrusDisponibility setDisableCartBtn={setDisableCartBtn} value={quantityValue} data={props.tyreData} setIsInternalclient={setIsInternalclient} />
            ) : (
              <Disponibility value={quantityValue} limit={props.tyreData.available} title={getAvailablityTitle()} />
            )}
            {marketplace == Marketplaces.RETAILER || marketplace == Marketplaces.PUNCHOUT ?
              calcLimit() ?
                <QuantityInput setValue={setQuantityValue} value={quantityValue} uv={props.tyreData?.available?.uv} />
                : !calcLimit() && props.tyreData?.add_to_cart ? <QuantityInput uv={Number(props.tyreData?.available?.uv)} setValue={setQuantityValue} value={quantityValue} /> : null
              :
              <QuantityInput setValue={setQuantityValue} value={quantityValue} />
            }

            <div className="disponibility-porduct">
              {t('_up_to')} :&nbsp;
              <span className="disponibility-count">
                {calcLimit()} {t('_products')}
              </span>
            </div>
          </div>
          {props.openModalTyre && props.deleteItem ? (
            <div className="cart">
              <div className="cart-price">{props.tyreData.price ? `${CURRENCY[user!.currency]}${props.tyreData.price}` : '-'} </div>
              <div className="cart-button">
                <button className="button-edit" onClick={() => props.openModalTyre && props.openModalTyre(props.tyreData.tyre_id)}>
                  <FontAwesomeIcon icon={faPen} className="icon-edit" />
                </button>
                <button className="button-delete" onClick={() => props.deleteItem && props.deleteItem(props.tyreData.tyre_id)}>
                  <FontAwesomeIcon icon={faRemove} className="icon-delete" />
                </button>
              </div>
            </div>
          ) : (
            <div className="cart">
              <div className="cart-price">
                <CarPartPrice currency={currencies.EUR} price={props.tyreData?.price} priceVAT={props.tyreData?.price?.price_with_vat} quantityValue={quantityValue} part />
              </div>
              <div className="cart-button">
                {/* <div className="button">
                  <FileButton />
                </div> */}
                {user?.numcli === Number(process.env.REACT_APP_CIRRUS_NUMCLI) && contactUsBtn() && !isInternalclient ? contactUsBtn() : (
                  props.tyreData.add_to_cart &&
                  <div className="button">
                    <CartListButton disableCartBtn={disableCartBtn} onClick={handleAddToCart} cartLoading={cartLoading} value={quantityValue} limit={calcLimit()} addToCart={props.tyreData?.add_to_cart} price={props.tyreData?.price} />
                  </div>
                )}
              </div>
              {showPrice ? quantityValue > 1 && getPrice() ? <div className="oem-price_per_unit">P/U: {CURRENCY[user.currency]}{getPrice()}</div> : '' : ''}
            </div>
          )}

        </div>
      </div>
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        shouldCloseOnOverlayClick={false}
        childrenComponent={<ItemViewModal setOpenModal={setOpenModal} data={props.tyreData} />}
      />
    </div>
  );
};

export default TyreCard;
